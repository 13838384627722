/* =============================================================================
 * Huge button
============================================================================= */
.c-huge-button {
	display:inline-block;
	width:100%;
	padding:var(--space-m);
	background-color:var(--color-blue);
	color:var(--color-white);
	text-align:center;
	text-decoration:none;
	transition:background-color 150ms;
}
.c-huge-button:focus,
.c-huge-button:hover,
.c-huge-button:active {
	background-color:var(--color-blue-dark);
	color:var(--color-white);
}
.c-huge-button:visited {
	color:var(--color-white);
}
