/* =============================================================================
 * Carousel
 * postcss-bem-linter:define c-carousel
============================================================================= */
.c-carousel {
	position:relative;
	width:100%;
	/* overflow:hidden; */
	transition:height 150ms;
}
.c-carousel__items {
	margin:0;
	padding:0;
	list-style:none;
}
.c-carousel.is-inited .c-carousel__items {
	display:flex;
}
.c-carousel__wrapper {
	height:100%;
	overflow-x:auto;
	overflow-y:hidden;
	-webkit-overflow-scrolling:touch;
	scroll-snap-type:mandatory;
	scroll-snap-destination:0 100%;
	scroll-snap-points-x:repeat(100%);
}
.c-carousel__prev-button {
	position:absolute;
	top:0;
	left:0;
	z-index:10;
	width:50px;
	height:100%;
}
.c-carousel__next-button {
	position:absolute;
	top:0;
	right:0;
	z-index:10;
	width:50px;
	height:100%;
}
.c-carousel__slide {
	display:flex;
	scroll-snap-align:start;
}
.c-carousel__slide > .o-grid {
	flex:1;
}
.c-carousel__item {
	display:flex;
	justify-content:center;
	align-items:center;
	min-height:300px;
	width:100%;
}

@media (min-width:768px) {
	.c-carousel__wrapper {
		overflow-x:hidden;
		overflow-y:hidden;
	}
	.c-carousel__item {
		min-height:380px;
	}
	.c-carousel__item-content {
		width:calc(100% - 300px);
		font:var(--type-sz4-medium);
	}
	.c-carousel__item-title {
		font:var(--type-sz8-bold);
	}
}
