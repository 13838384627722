/* =============================================================================
 * Icon
 * postcss-bem-linter: define c-icon
============================================================================= */
.c-icon {
	display:inline-block;
	width:24px;
	height:24px;
	vertical-align:middle;
}
.c-icon--xs {
	width:16px;
	height:16px;
}
.c-icon--s {
	width:20px;
	height:20px;
}

/*
.c-icon--m {
	// Default; see first rule in this file
}
*/
.c-icon--l {
	width:32px;
	height:32px;
}
.c-icon--xl {
	width:40px;
	height:40px;
}
.c-icon--xxl {
	width:52px;
	height:52px;
}
.c-icon--blue {
	color:var(--color-blue);
}
.c-icon--green {
	color:var(--color-green);
}
.c-icon--black {
	color:var(--color-black);
}
.c-icon--white {
	color:var(--color-white);
}
.c-icon--grey-lightest {
	color:var(--color-grey-lightest);
}
.c-icon--grey-light {
	color:var(--color-grey-light);
}
.c-icon--grey-base {
	color:var(--color-grey);
}
.c-icon--grey-dark {
	color:var(--color-grey-dark);
}
.c-icon--grey-darkest {
	color:var(--color-grey-darkest);
}
.c-icon svg {
	display:block;
	width:100%;
	height:100%;
	fill:currentColor;
}
.c-icon.is-highlight::after {
	position:absolute;
	top:0;
	right:0;
	width:0.6em;
	height:0.6em;
	border:0.13em solid var(--color-white);
	border-radius:50%;
	background-color:rgb(206, 19, 41);
	content:"";
}
