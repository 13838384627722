/* =============================================================================
 * Pop out
============================================================================= */
.c-pop-out {
	display:none;
	position:absolute;
	right:0;
	z-index:2;
	min-height:72px;
	margin-left:-300px;
	padding:var(--space-m);
	outline:0;
	box-shadow:0 10px 30px rgba(0, 0, 0, 0.2);
	background:#fff;
}
.c-pop-out.is-open {
	display:block;
}
.c-pop-out__close {
	position:absolute;
	top:0;
	right:var(--space-xs);
	margin:0;
	padding:var(--space-xs);
	border:none;
	background:transparent;
}
.js-pop-out-arrow {
	position:relative;
}
.js-pop-out-arrow::after {
	position:absolute;
	right:calc(50% - 8px);
	bottom:-1px;
	z-index:3;
	width:0;
	height:0;
	border-right:8px solid transparent;
	border-bottom:8px solid #fff;
	border-left:8px solid transparent;
	content:"";
}
