/* =============================================================================
 * Chart
 * postcss-bem-linter:define c-chart
============================================================================= */
.c-chart table {
	display:none;
}
.no-js .c-chart table {
	display:table;
}
