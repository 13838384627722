/* =============================================================================
 * Card
 * postcss-bem-linter: define c-card
============================================================================= */
.c-card {
	position:relative;
	box-shadow:0 0 var(--space-s) rgba(0, 0, 0, 0.1);
	background-color:var(--color-white);
}
.c-card--bg-grey {
	background:var(--color-grey-lightest);
}
.c-card__inner {
	display:flex;
	justify-content:space-between;
	padding:var(--space-m);
	flex-flow:row wrap;
}
.c-card__image {
	width:100%;
	height:auto;
}
.c-card__icon {
	flex:0 0 4em;
	width:4em;
	height:4em;
	margin-right:1em;
	padding:0.5em;
}
.c-card__date {
	font:var(--type-sz2);
}
.c-card__link {
	text-decoration:none;
}
.c-card__link:focus::before,
.c-card__link:hover::before {
	outline:2px dotted currentcolor;
	box-shadow:0 0 var(--space-m) rgba(0, 0, 0, 0.1);
}
.c-card__link:focus,
.c-card__link:active,
.c-card__link:active::before,
.c-card__link:hover::before {
	outline:0;
}
.c-card__link::before {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	content:"";
	transition:box-shadow 150ms;
}
.c-card__title {
	margin-bottom:var(--space-s);
	font:var(--type-sz5-bold);
}
.c-card__title--sz4-medium {
	font:var(--type-sz4-medium);
}
.c-card__title--sz5-medium {
	font:var(--type-sz5-medium);
}
.c-card__body {
	flex:1 0 100%;
}
.c-card__icon ~ .c-card__body {
	flex:1;
}
.c-card__content {
	margin:0;
	font:var(--type-sz4);
}
.c-card__cta {
	flex:3 0 100%;
	margin-top:1em;
	font:var(--type-sz4);
}
.c-card__bottom-content {
	flex:3 0 100%;
	margin-top:1em;
}
.c-card--lineup {
	margin-bottom:var(--space-m);
}
.c-card--lineup .c-card__icon {
	width:4em;
	height:4em;
	margin-left:2em;
	padding:0.5em;
}
.c-card--lineup .c-card__body {
	font:var(--type-sz4);
}
.c-card--lineup .c-card__body :last-child {
	margin-bottom:0;
}
.c-card--lineup .c-card__body .c-card__title {
	font:var(--type-sz4-medium);
}
.c-card--lineup .c-card__body .c-card__title--highlight::before {
	position:absolute;
	margin-left:-0.6em;
	color:rgb(206, 19, 41);
	content:"\2022";
}
.c-card__title-text {
	margin-right:0.5em;
}
.c-card--lineup .c-card__cta {
	margin-top:1em;
	font:var(--type-sz4);
}
.c-card--lineup .c-card__title-text {
	color:var(--color-black);
}

@supports (display:grid) {
	.c-card--lineup .c-card__inner {
		display:grid;
		align-items:flex-start;
		grid-gap:1em;
		grid-template-columns:1fr 80px;
	}
	.c-card--lineup .c-card__icon {
		order:1;
		margin-top:0;
	}
	.c-card--lineup .c-card__body {
		order:0;
	}
	.c-card--lineup .c-card__bottom-content {
		order:1;
		grid-column:span 3;
	}
	.c-card--lineup .c-card__cta {
		order:2;
		grid-column-end:span 2;
	}

	@media (min-width:600px) {
		.c-card--lineup .c-card__inner {
			grid-template-columns:80px 1fr 35%;
		}
		.c-card--lineup .c-card__icon {
			order:0;
			margin-left:0;
		}
		.c-card--lineup .c-card__bottom-content {
			order:2;
			grid-column:span 3;
		}
		.c-card--lineup .c-card__cta {
			order:1;
			margin-top:1.8em;
			grid-column-end:unset;
		}
	}
}
