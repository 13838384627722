/* =============================================================================
 * List
 * postcss-bem-linter: define c-list
============================================================================= */
.c-list {
	margin:0;
	padding:0;
	list-style:none;
}
.c-list--space-bottom {
	margin-bottom:var(--space-m);
}
.c-list__item {
	margin-bottom:var(--space-s);
}
.c-list--tight .c-list__item {
	margin-bottom:var(--space-xs);
}
.c-list__item:last-child {
	margin-bottom:0;
}
.c-list__item--divided {
	padding:var(--space-m) 0;
	border-top:1px solid var(--color-black);
}
.c-list__item--divided:last-child {
	border-bottom:1px solid var(--color-black);
}
.c-list__item > * {
	flex:0 1 auto;
}
.c-list__item > *:only-child {
	flex:1 1 auto;
}

/* Inline
----------------------------------------------------------------------------- */
.c-list--inline {
	display:flex;
	flex-wrap: wrap;
}

/* Columns
----------------------------------------------------------------------------- */
.c-list--2-columns,
.c-list--3-columns,
.c-list--4-columns,
.c-list--6-columns {
	display:flex;
	flex-wrap:wrap;
}
.c-list--2-columns .c-list__item,
.c-list--3-columns .c-list__item,
.c-list--4-columns .c-list__item,
.c-list--6-columns .c-list__item {
	display:flex;
	flex-direction:column;
	flex:0 1 auto;
	width:100%;
	margin-bottom:var(--space-s);
}

@media screen and (min-width:600px) {
	.c-list--2-columns,
	.c-list--3-columns,
	.c-list--4-columns,
	.c-list--6-columns {
		margin-left:calc(var(--space-s) * -1);
	}
	.c-list--2-columns .c-list__item,
	.c-list--3-columns .c-list__item,
	.c-list--4-columns .c-list__item,
	.c-list--6-columns .c-list__item {
		width:calc(50% - var(--space-s));
		margin-left:var(--space-s);
	}
}

@media screen and (min-width:1000px) {
	.c-list--2-columns,
	.c-list--3-columns,
	.c-list--4-columns,
	.c-list--6-columns {
		margin-left:calc(var(--space-m) * -1);
	}
	.c-list--2-columns .c-list__item,
	.c-list--3-columns .c-list__item,
	.c-list--4-columns .c-list__item,
	.c-list--6-columns .c-list__item {
		margin-left:var(--space-m);
	}
	.c-list--2-columns .c-list__item {
		width:calc(50% - var(--space-m));
	}
	.c-list--3-columns .c-list__item {
		width:calc(33.3333% - var(--space-m));
	}
	.c-list--4-columns .c-list__item {
		width:calc(25% - var(--space-m));
	}
	.c-list--6-columns .c-list__item {
		width:calc(16.6666% - var(--space-m));
	}
}
