/* =============================================================================
 * Compare
============================================================================= */
.c-compare {
	margin:0;
}
.c-compare__controls {
	display:none;
}
.js .c-compare__controls {
	position:fixed;
	right:0;
	bottom:0;
	left:0;
	z-index:20;
	text-align:center;
}
.c-compare__wrapper {
	display:inline-block;
	padding:calc(var(--space-m) - var(--space-xs));
	border-radius:40px 40px 0 0;
	box-shadow:0 0 10px 0 rgba(0, 0, 0, 0.4);
	background:var(--color-purple);
}
.no-js .c-compare__controls,
.js .c-compare__controls--show {
	display:inline-block;
}
.js .c-compare__controls .c-button {
	margin:var(--space-xs);
	border:none;
}
.c-compare__clear {
	padding-right:var(--space-s);
}
.no-js .c-compare__clear {
	display:none;
}
.c-compare__clear .c-icon {
	width:14px;
	height:14px;
	margin-right:0;
	margin-left:var(--space-s);
}
