/* =============================================================================
 * Cards container
 * postcss-bem-linter: define c-cards-container
============================================================================= */
.c-cards-container {
	position:relative;
	width:100%;
	background-color:transparent;
	display: flex;
	flex-direction: column;
}

.c-cards-container__cards {
	display: flex;
	flex-direction: column;
	gap: 40px;
}

@media screen and (min-width:769px) {
	.c-cards-container__cards {
		flex-direction: row;
	}
}