/* =============================================================================
 * Checkbox
 *
 * 1. We use flex to ensure that if the label text wraps, it does not wrap below
 *    the checkbox.
 *
 * postcss-bem-linter: define checkbox
============================================================================= */
.c-checkbox {
	display:-ms-flexbox;
	display:flex; /* 1 */
	-ms-flex-align:center;
	align-items:center;
}
.c-checkbox input {
	-ms-flex:none;
	flex:none; /* 1 */
}
.c-checkbox:not(.c-form-item) label {
	margin-left:0.5em;
}
