/* =============================================================================
 * Logo
 *
 * 1. Remove margins if an h1 element is used.
 * 2. For text fallback if the image doesn’t load. Not displayed in all
 *    browsers.
 *
 * postcss-bem-linter: define logo
============================================================================= */
.c-logo {
	height:100%;
	margin:0; /* 1 */
	color:#333; /* 2 */
	font:23px/30px "Gill Sans Medium", Arial, sans-serif;
	font:var(--type-sz5); /* 2 */
}
.c-logo a {
	display:block;
	height:100%;
	color:inherit; /* 2 */
	text-decoration:none;
	word-break:break-word;
}
.c-logo--subsite a {
	display:flex;
}
.c-logo img {
	display:block;
	max-height:100%;
	width:auto;
	height:auto;
	transition:opacity 100ms;
}
.c-logo--subsite img {
	max-height:var(--space-xl);
}
.c-logo a:hover img {
	opacity:0.8;
}
.c-logo__text {
	display:flex;
	align-items:center;
	margin:0 var(--space-m);
	padding-left:var(--space-m);
	border-left:1px solid var(--color-grey);
	font:var(--type-sz6-bold);
}

@media screen and (max-width:1120px) {
	.c-logo__text {
		margin:0 var(--space-s);
		padding-left:var(--space-s);
		font:var(--type-sz4-bold);
	}
	.c-logo--subsite img {
		max-height:var(--space-l);
	}
}

@media screen and (max-width:767px) {
	.c-logo--subsite {
		height:auto;
	}
	.c-logo--subsite a {
		flex-wrap:wrap;
	}
	.c-logo--subsite img {
		max-height:var(--space-m);
	}
	.c-logo__text {
		width:100%;
		margin:6px 0 0;
		padding:0;
		border:none;
		font:var(--type-sz2-bold);
	}
}
