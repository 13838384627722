/* =============================================================================
 * Sub navigation
 * postcss-bem-linter: define sub-navigation
============================================================================= */
.c-sub-navigation {
	margin-top:var(--space-s);
}
.c-sub-navigation__title {
	margin-bottom:0;
	font:var(--type-sz5-bold);
}
.c-sub-navigation--info .c-sub-navigation__title {
	margin-bottom:var(--space-xs);
}
.c-sub-navigation__byline {
	margin-top:calc(var(--space-xs) * -1);
	margin-bottom:var(--space-xs);
	display: block;
}
.c-sub-navigation ul {
	margin:0;
	padding:0;
	list-style:none;
}
.c-sub-navigation__item {
	margin-bottom:var(--space-s);
}
.c-sub-navigation__item:last-child {
	margin-bottom:0;
}
.c-sub-navigation__link,
.c-sub-navigation__link:visited {
	display:block;
	color:var(--color-blue);
	font:var(--type-sz4-medium);
	text-decoration:none;
}
.c-sub-navigation__link:hover,
.c-sub-navigation__link:focus {
	color:var(--color-blue);
	text-decoration:underline;
}
.c-sub-navigation ul ul {
	padding-left:20px;
}

/* Item with children
----------------------------------------------------------------------------- */



/* Expanded item
----------------------------------------------------------------------------- */
.c-sub-navigation__item--is-expanded {
	background:#eee;
}


/* Selected page link
----------------------------------------------------------------------------- */
.c-sub-navigation a[aria-current],
.c-sub-navigation a[aria-current]:visited {
	color:var(--color-black);
}

.c-sub-navigation--left {
	padding:0;
	margin-bottom: 30px;
}

.c-sub-navigation--left ul + h3 {
margin-top: var(--space-m);
}
.c-sub-navigation__hide-toggle-button {
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	background-color: transparent;
	border: none;
	padding: 13px 0;
	
}
.c-sub-navigation__content {
	display: none;
}
.c-sub-navigation__hide-toggle-arrow {
	transition: all 0.3s;
}
@media screen and (min-width:768px) {
	.c-sub-navigation__hide-toggle-arrow {
		display: none;
	}
	.c-sub-navigation__content {
		display: block;
	}
	.c-sub-navigation__hide-toggle-button {
		pointer-events: none;
	}
	.c-sub-navigation {
		margin-top:var(--space-s);
		padding-left:var(--space-m);
		border-left:1px solid var(--color-grey-light);
		padding:0 0 var(--space-xl) var(--space-l);
	}
	.c-sub-navigation--left {
		border-right:1px solid var(--color-grey-light);
		border-left: none;
	}
}

@media screen and (min-width:992px) {
	.c-sub-navigation {
		padding:0 0 var(--space-l) var(--space-xxl);
	}
	.c-sub-navigation--left {
		padding:0 var(--space-xl) var(--space-l) 0;
	}
}
