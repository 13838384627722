/* =============================================================================
 * Card
 * postcss-bem-linter: define c-card-subscription
============================================================================= */
.c-card-subscription {
	position:relative;
	box-shadow:0 0 var(--space-s) rgba(0, 0, 0, 0.1);
	background-color:var(--color-white);
	margin-bottom: var(--space-l)
}
.c-card-subscription--bg-grey {
	background:var(--color-grey-lightest);
}
.c-card-subscription__inner {
	display:flex;
	justify-content:space-between;
	padding:var(--space-m);
	flex-flow:row wrap;
}
.c-card-subscription__image {
	width:100%;
	height:auto;
}
.c-card-subscription__icon {
	flex:0 0 4em;
	width:4em;
	height:4em;
	margin-right:1em;
	padding:0.5em;
}

.c-card-subscription__top-hr {
	border-top: 1px solid var(--color-green);
}
.c-card-subscription__date {
	font:var(--type-sz2);
}
.c-card-subscription__link {
	text-decoration:none;
}
.c-card-subscription__link:focus::before,
.c-card-subscription__link:hover::before {
	outline:2px dotted currentcolor;
	box-shadow:0 0 var(--space-m) rgba(0, 0, 0, 0.1);
}
.c-card-subscription__link:focus,
.c-card-subscription__link:active,
.c-card-subscription__link:active::before,
.c-card-subscription__link:hover::before {
	outline:0;
}
.c-card-subscription__link::before {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	content:"";
	transition:box-shadow 150ms;
}
.c-card-subscription__title {
	margin-bottom:var(--space-s);
	font:var(--type-sz5-bold);
}
.c-card-subscription__title--sz4-medium {
	font:var(--type-sz4-medium);
}
.c-card-subscription__title--sz5-medium {
	font:var(--type-sz5-medium);
}
.c-card-subscription__body {
	flex:1 0 100%;
}
.c-card-subscription__icon ~ .c-card-subscription__body {
	flex:1;
}
.c-card-subscription__content-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	justify-content: space-between;
}
.c-card-subscription__content {
	margin:0;
	font:var(--type-sz4);
}
.c-card-subscription__content--50 {
	width: 50%;
	display: flex;
	flex-direction: column;
}
.c-card-subscription__cta {
	flex:3 0 100%;
	margin-top:1em;
	font:var(--type-sz4);
}

.c-card-subscription__bottom-content {
	flex:3 0 100%;
	margin-top:1em;
}
.c-card-subscription--lineup {
	margin-bottom:var(--space-m);
}
.c-card-subscription--lineup .c-card-subscription__icon {
	width:4em;
	height:4em;
	margin-left:2em;
	padding:0.5em;
}
.c-card-subscription--lineup .c-card-subscription__body {
	font:var(--type-sz4);
}
.c-card-subscription--lineup .c-card-subscription__body :last-child {
	margin-bottom:0;
}
.c-card-subscription--lineup .c-card-subscription__body .c-card-subscription__title {
	font:var(--type-sz4-medium);
}
.c-card-subscription--lineup .c-card-subscription__body .c-card-subscription__title--highlight::before {
	position:absolute;
	margin-left:-0.6em;
	color:rgb(206, 19, 41);
	content:"\2022";
}
.c-card-subscription__title-text {
	margin-right:0.5em;
	font-weight: bolder;
	font:var(--type-sz5);
}
.c-card-subscription--lineup .c-card-subscription__cta {
	margin-top:1em;
	font:var(--type-sz4);
}
.c-card-subscription--lineup .c-card-subscription__title-text {
	color:var(--color-black);
}

.c-card-subscription__address-text {
	color:var(--color-grey-dark);
	font:var(--type-sz4);
}

@supports (display:grid) {
	.c-card-subscription--lineup .c-card-subscription__inner {
		display:grid;
		align-items:flex-start;
		grid-gap:1em;
		grid-template-columns:1fr 80px;
	}
	.c-card-subscription--lineup .c-card-subscription__icon {
		order:1;
		margin-top:0;
	}
	.c-card-subscription--lineup .c-card-subscription__body {
		order:0;
	}
	.c-card-subscription--lineup .c-card-subscription__bottom-content {
		order:1;
		grid-column:span 3;
	}
	.c-card-subscription--lineup .c-card-subscription__cta {
		order:2;
		grid-column-end:span 2;
	}

	@media (min-width:600px) {
		.c-card-subscription--lineup .c-card-subscription__inner {
			grid-template-columns:80px 1fr 35%;
		}
		.c-card-subscription--lineup .c-card-subscription__icon {
			order:0;
			margin-left:0;
		}
		.c-card-subscription--lineup .c-card-subscription__bottom-content {
			order:2;
			grid-column:span 3;
		}
		.c-card-subscription__content-container {
			flex-direction: row;
		}
		.c-card-subscription--lineup .c-card-subscription__cta {
			order:1;
			margin-top:1.8em;
			grid-column-end:unset;
		}
		.c-card-subscription__cta {
			flex:3 0 100%;
			display: flex;
			align-self: end;
			margin-top:1em;			
			font:var(--type-sz4);
			text-align: center;

		}
	}
}
