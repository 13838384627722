/* =============================================================================
 * Critical info
 * postcss-bem-linter: define c-critical-info
============================================================================= */
.c-critical-info {
	padding: var(--space-s);
	background-color: var(--color-red);
	color: var(--color-white);
}
@media screen and (min-width:768px) {
	.c-critical-info {
		padding: var(--space-s) var(--space-l);
	}
}
.c-critical-info__inner {
	display: flex;
	align-items: center;
	max-width: 1200px;
	margin: 0 auto;
	gap: var(--space-m);
}
.c-critical-info__icon[class] {
	display: none;
	flex-shrink: 0;
	margin-bottom: 6px;
}
@media screen and (min-width:768px) {
	.c-critical-info__icon[class] {
		display: block;
	}
}
.c-critical-info a {
	color: inherit;
	font-weight: 700;
}
.c-critical-info p:last-of-type {
	margin: 0;
}
