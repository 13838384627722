/* =============================================================================
 * Process list
============================================================================= */
.c-process-list {
	counter-reset:process-list__counter;
}
.c-process-list__item {
	display:flex;
	margin-bottom:var(--space-m);
}
.c-process-list__item:last-child {
	margin-bottom:0;
}
.c-process-list__item::before {
	flex:0 0 auto;
	width:40px;
	height:40px;
	margin-right:var(--space-m);
	border-radius:100%;
	background:var(--color-green);
	color:#fff;
	font-family:var(--type-family-medium);
	line-height:40px;
	content:counter(process-list__counter);
	counter-increment:process-list__counter;
	text-align:center;
}
.c-process-list__wrapper {
	flex:1 1 auto;
	padding-top:4px;
}
.c-process-list__title {
	margin-bottom:var(--space-s);
	font:var(--type-sz5-medium);
}

@media screen and (max-width:767px) {
	.c-process-list__item::before {
		width:30px;
		height:30px;
		margin-right:var(--space-s);
		font-size:15px;
		line-height:30px;
	}
}
