/* =============================================================================
 * Cookie information
============================================================================= */
.c-cookie-information {
	position:fixed;
	right:0;
	bottom:0;
	left:0;
	z-index:11;
	padding:var(--space-s) 0;
	background:var(--color-blue);
}
.c-cookie-information__inner {
	display:flex;
	justify-content:space-between;
	align-items:center;
}
.c-cookie-information__content {
	flex:1 1 auto;
	margin-right:var(--space-l);
}
.c-cookie-information__content a {
	text-decoration:underline;
}
.c-cookie-information__content a,
.c-cookie-information__content p {
	color:#fff;
}
.c-cookie-information__content p {
	font:var(--type-sz3);
}
.c-cookie-information__content p,
.c-cookie-information__form {
	margin:0;
}
.c-cookie-information__form {
	flex:0 1 auto;
}
.c-cookie-information__button:hover,
.c-cookie-information__button:focus {
	background:#fff;
	color:var(--color-black);
}

@media screen and (max-width:991px) {
	.c-cookie-information__inner {
		display:block;
	}
	.c-cookie-information__content p {
		margin-bottom:var(--space-xs);
	}
}

@media print {
	.c-cookie-information {
		display:none;
	}
}
