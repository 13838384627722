/* =============================================================================
 * Tabs
============================================================================= */


/* The tabs container
----------------------------------------------------------------------------- */
.c-tabs {
	margin:var(--vspace) 0;
}

.c-tabs--type2 .c-tabs__tablist-item {
	flex: 1;
}

.c-tabs--type2 .c-tabs__tablist-item .c-tabs__tablist-trigger {
	border-color:var(--color-green);
	background-color:var(--color-white);
	color:var(--color-green);
	border: 1px solid var(--color-green);
	border-radius: 10px;
	padding:var(--space-s) var(--space-l);
}

.c-tabs--type2 .c-tabs__tablist-trigger[aria-selected="true"] {
	background-color: var(--color-green);
	border: none;
	color: white
}


/* The tablist
----------------------------------------------------------------------------- */
.c-tabs--enabled .c-tabs__tablist {
	display:flex;
	justify-content: space-between;
	flex-wrap: wrap;
	width:100%;
	margin:0;
	padding:0;
	list-style:none;
}

.c-tabs--type2 .c-tabs__tablist {
	justify-content: flex-start;
	flex-wrap: wrap;
	gap: var(--space-s);
}

.c-tabs__tablist-item {
	display:flex;
	flex:1;
}
.c-tabs__tablist-trigger {
	display:block;
	flex:0 1 auto;
	width:100%;
	padding:var(--space-s) var(--space-m);
	border-top:1px solid var(--color-blue-dark);
	border-right:1px solid var(--color-blue-dark);
	border-bottom:0;
	border-left:1px solid var(--color-blue-dark);
	background-color:var(--color-blue);
	color:var(--color-white);
	font:var(--type-sz4-medium);
	text-align:center;
	text-decoration:none;
	cursor:pointer;
}
.c-tabs__tablist-trigger:hover {
	background-color:var(--color-blue-dark);
	color:var(--color-white);
}
.c-tabs__tablist-trigger:focus {
	outline-offset:-2px;
}
.c-tabs__tablist-trigger[aria-selected="true"] {
	border-color:var(--color-blue);
	background-color:var(--color-white);
	color:var(--color-blue);
}
.c-tabs__tablist-trigger > .c-icon {
	position:relative;
	margin-top:-4px;
	margin-right:var(--space-xs);
}


/* The tabpanels
----------------------------------------------------------------------------- */
.c-tabs--enabled .c-tabs__tabpanel {
	padding:var(--space-m) 0;
}
.c-tabs__tabpanel:focus {
	outline-offset:-2px;
}
