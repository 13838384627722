/* =============================================================================
 * Icon
 * postcss-bem-linter: define c-chat
============================================================================= */
.c-chat {
	width:100%;
	margin:0 auto;
}
.c-chat__form-wrapper {
	margin-bottom:var(--space-m);
	padding:var(--space-m);
	background:var(--color-grey-lightest);
}
.c-chat__form {
	display:flex;
	justify-content:flex-end;
	flex-wrap:wrap;
	padding-top:var(--space-m);
	border-top:1px solid var(--color-grey-light);
}
.c-chat__form-content {
	justify-content:flex-start;
	flex:1 1 auto;
}
.c-chat__form-item {
	display:flex;
	align-items:flex-start;
	flex:0 1 auto;
}
.c-chat__form-item > * {
	flex:0 1 auto;
}
.c-chat__form-item label {
	flex:1 0 90px;
	margin:var(--space-s) var(--space-m) 0 0;
	text-align:right;
}
.c-chat__form-item:first-child:not(:last-child) {
	margin-bottom:var(--space-m);
}
.c-chat__form-submit {
	display:flex;
	align-items:flex-end;
	flex:0 1 auto;
	margin-top:var(--space-m);
	margin-left:var(--space-m);
}
.c-chat__info {
	display:flex;
	justify-content:space-between;
	width:100%;
}
.c-chat__status {
	flex:0 1 auto;
	margin-right:var(--space-xs);
}
.c-chat__status::before {
	display:inline-block;
	width:13px;
	height:13px;
	margin-right:var(--space-xs);
	border-radius:100%;
	content:"";
	vertical-align:-1px;
}
.c-chat__status--active::before {
	background:var(--color-green);
}
.c-chat__status--inactive {
	margin-bottom:0;
}
.c-chat__status--inactive::before {
	background:var(--color-red);
}
.c-chat__logo::before {
	display:inline-block;
	width:20px;
	height:22px;
	margin-right:var(--space-xs);
	background:url("/gui/images/logotype-symbol.svg") 0 0 / contain no-repeat;
	content:"";
	vertical-align:-6px;
}
.c-chat__post {
	text-align:right;
}
.c-chat__reply {
	margin-bottom:var(--space-m);
	border-bottom:1px solid var(--color-grey-light);
}
.c-chat__reply:last-child {
	margin-bottom:0;
	border:none;
}
.c-chat__post,
.c-chat__reply {
	color:var(--color-grey-dark);
}
.c-chat__meta,
.c-chat__text {
	padding-right:var(--space-m);
	padding-left:var(--space-m);
}
.c-chat__text {
	display:inline-block;
	margin-bottom:var(--space-s);
	padding:var(--space-s) var(--space-m);
	border-radius:26px;
	color:var(--color-black);
	text-align:left;
}
.c-chat__post .c-chat__text {
	background:var(--color-purple);
	color:var(--color-white);
}
.c-chat__reply .c-chat__text {
	display:inline-block;
	padding:var(--space-s) var(--space-m);
	border:1px solid var(--color-grey-light);
}

@media screen and (max-width:768px) {
	.c-chat__form-item {
		display:block;
	}
	.c-chat__form-item label {
		margin:0 0 8px;
		text-align:left;
	}
	.c-chat__logo::before {
		vertical-align:-8px;
	}
}
