/* =============================================================================
 * Custom radio
 *
 * 1. We use flex to ensure that if the label text wraps, it does not wrap below
 *    the radio button.
 *
 * postcss-bem-linter: define custom-radio
============================================================================= */
.c-custom-radio {
	display:-ms-flexbox;
	display:flex; /* 1 */
}
.c-custom-radio__input {
	display:inline-block;
	position:relative;
	-ms-flex:none;
	flex:none; /* 1 */
	width:34px;
	height:34px;
	vertical-align:middle;
}
.c-custom-radio input {
	position:absolute;
	top:0;
	left:0;
	z-index:1;
	width:100%;
	height:100%;
	opacity:0;
}
.c-custom-radio__input-graphic {
	position:absolute;
	top:10px;
	right:10px;
	bottom:10px;
	left:10px;
	border-radius:50%;
	box-shadow:0 0 0 7px #fff, 0 0 0 9px #ccc;
	background:#fff;
}
.c-custom-radio label {
	margin-top:4px;
	margin-bottom:0;
	margin-left:0.5em;
}


/* Checked
----------------------------------------------------------------------------- */
.c-custom-radio input:checked + .c-custom-radio__input-graphic {
	background:#000;
}


/* Hover
----------------------------------------------------------------------------- */
.c-custom-radio input:not([disabled]):hover + .c-custom-radio__input-graphic {
	box-shadow:0 0 0 7px #fff, 0 0 0 9px #666;
}


/* Focus
----------------------------------------------------------------------------- */
.c-custom-radio input:not([disabled]):focus + .c-custom-radio__input-graphic {
	box-shadow:0 0 0 7px #fff, 0 0 0 9px #666;
}


/* Disabled
----------------------------------------------------------------------------- */
.c-custom-radio input[disabled] + .c-custom-radio__input-graphic {
	opacity:0.5;
}
