/* =============================================================================
 * Chip checkbox
 *
 * postcss-bem-linter: define chip-checkbox
============================================================================= */
.c-chip-checkbox {
	display:inline-flex;
	position:relative;
}
.c-chip-checkbox input {
	position:absolute;
	top:0;
	left:0;
	z-index:1;
	opacity:0;
}
.c-chip-checkbox__input-graphic {
	position:absolute;
	top:0;
	right:0;
	bottom:0;
	left:0;
	border:2px solid #ccc;
	background:#fff;
}
.c-chip-checkbox label {
	min-width: 80px;
	padding:var(--space-xs) var(--space-s);
	border:1px solid #767f8d;
	border-radius:var(--space-m);
	background-color:var(--color-white);
	line-height:1.5;
	font:var(--type-sz3-medium);
	display:flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}
.c-chip-checkbox label:hover {
	background-color:var(--color-grey-lightest);
}
.c-chip-checkbox input:focus ~ label {
	outline: 2px dotted currentcolor;
}


/* Checked
----------------------------------------------------------------------------- */
.c-chip-checkbox input:checked ~ label {
	background-color: var(--color-blue);
	color:var(--color-white);
}
