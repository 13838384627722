/* =============================================================================
 * Width limiter
 * Limits width and centers container on page.
 * postcss-bem-linter: define width-limiter
============================================================================= */
.o-width-limiter {
	max-width:1440px;
	width:100%;
	margin-right:auto;
	margin-left:auto;
}
.o-width-limiter--startpage {
	max-width:1200px;
}
