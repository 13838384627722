/* =============================================================================
 * Date-badge
 * postcss-bem-linter: define c-date-badge
============================================================================= */
.c-date-badge {
	display:flex;
	color:var(--color-grey);
	text-align:center;
	flex-flow:column nowrap;
}
.c-date-badge__day {
	margin-top:-0.2em;
	font:var(--type-sz6-medium);
}
.c-date-badge__month,
.c-date-badge__year {
	margin-top:-0.3em;
	font:var(--type-sz3-medium);
}
