/* =============================================================================
 * Form-pdf
 *
 * postcss-bem-linter: define form-pdf
============================================================================= */
.c-form-pdf {
	display: flex;
	justify-content: space-between;
	margin-bottom: 60px;
	align-items: center;
}

.c-form-pdf__meta {
	text-align: right;
}
.c-form-pdf__meta p {
	margin-bottom: 10px;
}
