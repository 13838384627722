/* =============================================================================
 * Page
 * postcss-bem-linter: define o-page
============================================================================= */
.o-page {
	max-width:1200px;
	width:100%;
	margin:var(--space-m) auto;
	padding:0 var(--space-m);
}

@media screen and (min-width:769px) {
	.o-page {
		display:flex;
		margin:var(--space-xl) auto;
		padding:0 var(--space-m);
	}
	.o-page__main {
		flex:1 1 calc(100% * 8 / 12);
	}
	.o-page__main--narrow[class][class] {
		flex:0 1 auto;
		width:calc(100% * 8 / 12);
	}
	.o-page__aside {
		flex:1 1 calc(100% * 4 / 12);
		padding-left:var(--space-xxxl);
	}
}

@supports (display:grid) {
	/* stylelint-ignore */
	@media screen and (min-width:769px) {
		.o-page {
			display:grid;
			grid-template-columns:repeat(12, 1fr);
			grid-gap:var(--space-m);
		}
		.o-page__main--narrow[class][class],
		.o-page__main {
			width:auto;
			grid-column:span 8;
		}
		.o-page__main:last-child {
			grid-column:span 12;
		}
		.o-page__aside {
			width:auto;
			padding:0;
			grid-column:span 4;
		}
		
		.o-page__aside--narrow {
			grid-column:span 3;
		}
		
		.o-page__main--narrower {
			grid-column:span 6;
		}
		.o-page__main--narrower:last-child {
			grid-column:span 9;
		}
	}

	@media screen and (min-width:992px) {
		.o-page {
			grid-gap:var(--space-xxxl);
		}
	}
}

@media print {
	.o-page__aside {
		display:none;
	}
}
