/* =============================================================================
 * File
 *
 * 1. Needed to remove c- because of Episerver built in framework
============================================================================= */
.file::before {
	display:inline-block;
	width:17px;
	height:20px;
	margin-right:8px;
	background:url("/gui/images/icons/file.svg") no-repeat 0 0;
	background-size:17px 20px;
	content:"";
	vertical-align:-3px;
}
