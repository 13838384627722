/* =============================================================================
 * Button as text
 *
 * 1. If this modifier is set, we don't touch the border. That allows us to,
 *    in the component, style the border whichever way we want. If we didn't
 *    do it this way, we'd have to "trump a trump" in components.
 * 2. This should correspond to the site's default line height. Be aware that
 *    some browsers do not want to make the line-height of button elements lower
 *    than what corresponds to "normal".
 * 3. Feel free to change the look of this, but make sure it's noticable enough.
 * 4. Recreate how your links look here.

 * postcss-bem-linter: define button-as-text
============================================================================= */
.t-button-as-text {
	margin:0;
	padding:0;
	background-color:transparent;
	color:inherit;
	line-height:normal; /* [2] */
	-webkit-appearance:none;
}
.t-button-as-text:not(.t-button-as-text--stylable-border) {
	/* [1] */
	border:none;
	border-radius:0;
}
.t-button-as-text:focus {
	outline:2px dotted #000; /* [3] */
}


/* Link
----------------------------------------------------------------------------- */
.t-button-as-text--link {
	/* [4] */
	color:#09f;
	text-decoration:underline;
}
.t-button-as-text--link:hover,
.t-button-as-text--link:focus {
	color:var(--color-black);
}
