/* =============================================================================
 * Loading
 *
 * postcss-bem-linter: define loading
============================================================================= */

.c-loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color: rgba(255, 255, 255, 0.85);
    display: none;
    align-items: center;
    justify-content: center;
    padding-bottom: 10vh;
    flex-direction: column;
}

.c-loading__text {
    margin-top: 12px;
}

.c-loading__indicator {
    border: 4px solid var(--color-grey-light);
    border-top: 4px solid var(--color-blue);
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}