/* =============================================================================
 * Event item
============================================================================= */
.c-event-item {
	position:relative;
	width:100%;
}
.c-event-item__image-wrapper {
	position:relative;
	margin-bottom:var(--space-s);
}
.c-event-item__link,
.c-event-item__link:visited {
	color:var(--color-blue);
	font:var(--type-sz4-medium);
	text-decoration:none;
}
.c-event-item__link:hover {
	text-decoration:underline;
}
.c-event-item__link::after {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	content:"";
}
.c-event-item__title {
	margin-bottom:var(--space-s);
	padding-bottom:var(--space-s);
	border-bottom:1px solid var(--color-grey-light);
}
.c-event-list__details {
	font:var(--type-sz3);
}

@supports (object-fit:cover) {
	.c-event-item__image-wrapper::before {
		display:block;
		width:100%;
		padding-top:calc(9 / 16 * 100%);
		content:"";
	}
	.c-event-item__image {
		display:flex;
		position:absolute;
		top:0;
		right:0;
		bottom:0;
		left:0;
		justify-content:center;
		align-items:center;
		width:100%;
		height:100%;
		object-fit:cover;
	}
}
