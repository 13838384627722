/* =============================================================================
 * Steps
============================================================================= */
.c-steps__description {
	margin:0;
	padding:var(--space-s);
	background:var(--color-blue);
	color:var(--color-white);
	font:var(--type-sz4-medium);
}
.c-steps__list {
	position:relative;
	margin:0;
	padding:var(--space-s) var(--space-s) 0 calc(var(--space-m) + 35px);
	border:1px solid var(--color-grey-light);
	border-top:none;
	list-style:none;
}
.c-steps--borderless .c-steps__list {
	border:none;
}
.c-steps__item {
	position:relative;
	margin-bottom:var(--space-m);
}
.c-steps__item--no-accordion {
	margin-bottom:var(--space-m);
}
.c-steps__item:last-child {
	margin-bottom:0;
}
.c-steps__item::before {
	position:absolute;
	top:0;
	left:calc((var(--space-s) + 35px) * -1);
	width:35px;
	height:35px;
	border:5px solid var(--color-white);
	border-radius:35px;
	box-shadow:0 0 0 2px var(--color-grey);
	background:var(--color-white);
	content:"";
}
.c-steps__item::after {
	position:absolute;
	top:35px;
	left:calc(1px - (var(--space-m) + 38px) / 2);
	z-index:-1;
	width:2px;
	height:100%;
	background:var(--color-grey);
	content:"";
}
.c-steps__item:last-of-type::after {
	display:none;
}
.c-steps__item--upcoming::before {
	width:37px;
	height:37px;
	border:2px dashed var(--color-grey);
	box-shadow:none;
}
.c-steps__item--upcoming::after {
	width:0;
	border-left:2px dashed var(--color-grey);
	background:none;
}
.c-steps__item--done::before {
	background:var(--color-grey);
}
.c-steps__item--alert::before {
	box-shadow:0 0 0 2px var(--color-red);
	background:var(--color-red);
}
.c-steps__item--checked::before {
	background-color:var(--color-grey);
	background-image:url("/gui/images/icons/check-white.svg");
	background-position:center;
	background-size:100%;
	background-repeat:no-repeat;
}
.c-steps__item--crossed::before {
	box-shadow:0 0 0 2px var(--color-red);
	background-color:var(--color-red);
	background-image:url("/gui/images/icons/cross-white.svg");
	background-position:center;
	background-size:50%;
	background-repeat:no-repeat;
}
.c-steps__item--active::before {
	background:var(--color-green);
}
.c-steps__date {
	color:var(--color-grey-mediumlight);
	font:var(--type-sz4);
	font-style:italic;
}
.c-steps__title {
	margin-bottom:var(--space-s);
	font:var(--type-sz4-medium);
}
.c-steps__button {
	display:flex;
	justify-content:space-between;
	align-items:center;
	width:100%;
	margin:0;
	padding:var(--space-xs) 0;
	border:none;
	background-color:transparent;
	color:inherit;
	font:inherit;
	line-height:normal;
	text-align:left;
	cursor: pointer;
	-webkit-appearance:none;
}
.c-steps__button span {
	display:inline-block;
	flex:1 1 auto;
}
.c-steps__button .c-icon {
	flex:0 0 auto;
	width:14px;
	height:14px;
}
.js .c-steps__content {
	height:0;
	overflow:hidden;
	margin:0;
	padding:0;
	font:var(--type-sz3);
	transition:height 400ms;
}
.c-steps__item.is-collapsed .c-steps__content,
.c-steps__item.is-expanded .c-steps__content {
	height:auto;
}
.c-steps__item--no-accordion .c-steps__content {
	height:auto;
	padding-bottom:var(--space-m);
}
.c-steps__content p {
	margin-bottom:var(--space-s);
}
.c-steps__content ul {
	margin:0;
	padding:0;
	list-style:none;
}
.c-steps__content li span {
	display:block;
}
.c-steps__item--no-accordion .c-steps__title {
	line-height:2em;
}
.c-steps__item--no-accordion.c-steps__item--alert .c-steps__title {
	font:var(--type-sz4-bold);
}
.c-steps--hide-container .c-steps__list {
	border:none;
}
.c-steps--full-start .c-steps__item:last-child::after {
	display:none;
}

@media screen and (max-width:767px) {
	.c-steps__item::after {
		top:30px;
		left:calc(1px - (var(--space-m) + 43px) / 2);
	}
	.c-steps__item::before {
		width:30px;
		height:30px;
		margin-right:var(--space-s);
		font-size:15px;
		line-height:30px;
	}
}
