/* =============================================================================
 * Categories
============================================================================= */
.c-categories {
	margin-top:var(--space-s);
}
.c-categories__title {
	margin-bottom:var(--space-s);
	font:var(--type-sz5-bold);
}
.c-categories__list-title {
	margin-bottom:var(--space-s);
	font:var(--type-sz5-bold);
}
.c-categories__list {
	margin:0 0 var(--space-m) 0;
	padding:0;
	list-style:none;
}
.c-categories__item {
	margin-bottom:var(--space-s);
}
.c-categories__item:last-child {
	margin-bottom:0;
}
.c-categories__selected,
.c-categories__link {
	font:var(--type-sz4-medium);
}
.c-categories__link,
.c-categories__link:visited {
	display:block;
	color:var(--color-blue);
	text-decoration:none;
}
.c-categories__link:hover,
.c-categories__link:focus {
	color:var(--color-blue);
	text-decoration:underline;
}
.c-categories__button {
	display:flex;
	align-items:center;
	padding:0;
	border:none;
	background:transparent;
	color:inherit;
	color:var(--color-blue);
	font:inherit;
	font:var(--type-sz3-medium);
	text-align:left;
	cursor:pointer;
}
.c-categories__button .c-icon {
	width:12px;
	margin-left:var(--space-s);
}

@media screen and (min-width:992px) {
	.c-categories {
		padding:0 0 var(--space-l) var(--space-xxl);
	}
}

@media screen and (min-width:768px) {
	.c-categories {
		margin-top:var(--space-s);
		padding-left:var(--space-m);
		border-left:1px solid var(--color-grey-light);
	}
	.c-categories__title {
		display:none;
	}
	.c-categories__list:last-child {
		margin-bottom:0;
	}
}

@media screen and (max-width:767px) {
	.c-categories__title {
		font:var(--type-sz4-medium);
	}
	.c-categories__content {
		height:auto;
		overflow:hidden;
		font:18px/30px "Gill Sans Medium", Arial, sans-serif;
		font:var(--type-sz3-medium);
		transition:height 400ms;
	}
	.c-categories.is-collapsed .c-categories__content,
	.c-categories.is-expanded .c-categories__content {
		height:auto;
	}
	.js .c-categories__content {
		height:0;
	}
	.c-categories__list-title {
		font:var(--type-sz3-medium);
	}
	.c-categories__selected,
	.c-categories__link {
		font:var(--type-sz3-medium);
	}
}
