/* =============================================================================
 * Form-group
 *
 * postcss-bem-linter: define form-group
============================================================================= */
/* .c-form-group--inline {
	margin-top:-6px;
	margin-left:16px;
	padding-left:20px;
	border-left:4px solid var(--color-blue);
} */

.c-form-group:not(:last-of-type){
	margin-bottom:15px;
}
