/* =============================================================================
 * News grid
 * postcss-bem-linter: define c-news-grid
 * [1] min-height forces IE11 to collapse the height of .c-news-grid__item even if it
 *     contains an img. See https://github.com/philipwalton/flexbugs/issues/75.
============================================================================= */
.c-news-grid__item {
	min-height:1px; /* [1] */
	margin-bottom:var(--space-l);
}
