/* =============================================================================
 * Word break
 *
 * 1. https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/
============================================================================= */
.t-word-break {
	word-wrap:break-word; /* 1 */
	-ms-word-break:break-all; /* 1 */
	word-break:break-all; /* 1 */
	word-break:break-word; /* 1 */
	overflow-wrap:break-word; /* 1 */
}
