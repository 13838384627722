/* =============================================================================
 * Main navigation
============================================================================= */
.c-main-navigation {
	flex:1 1 auto;
	background-color:#0061a1;
	background-color:var(--color-blue);
	color:#fff;
	color:var(--color-white);
}
.c-main-navigation ul {
	margin:0;
	padding:0;
	list-style:none;
}
.c-main-navigation__item {
	display:flex;
	margin:0;
	transition:background-color 150ms;
}
.c-main-navigation__item--logout {
	margin-left:auto;
}
.c-main-navigation__link {
	display:block;
	position:relative;
	padding:24px 24px;
	padding:var(--space-m) var(--space-m);
	color:inherit;
	font:18px/30px "Gill Sans Medium", Arial, sans-serif;
	font:var(--type-sz4-medium);
	line-height:1;
	text-decoration:none;
	transition:background-color 200ms, color 150ms;
}
.c-main-navigation__link:visited {
	color:inherit;
}
.c-main-navigation__link:focus,
.c-main-navigation__link:hover {
	background-color:#004675;
	background-color:var(--color-blue-dark);
	color:#fff;
	color:var(--color-white);
}
.c-main-navigation__item.is-active .c-main-navigation__link {
	background-color:#004675;
	background-color:var(--color-blue-dark);
	color:#fff;
	color:var(--color-white);
	font:18px/30px "Gill Sans Medium", Arial, sans-serif;
	font:var(--type-sz4-medium);
	line-height:1;
}
.c-main-navigation__item.is-highlight .c-main-navigation__link::after {
	position:absolute;
	top:1.2em;
	width:0.6em;
	height:0.6em;
	margin-left:0.1em;
	border:0.13em solid var(--color-white);
	border-radius:50%;
	background-color:rgb(206, 19, 41);
	content:"";
}
.c-main-navigation__children {
	display:block;
	width:100%;
	overflow:hidden;
	transition:height 400ms;
}
.c-main-navigation--narrow {
	background-color:var(--color-white);
}
.c-main-navigation--narrow .c-main-navigation__item {
	position:relative;
	border-top:1px solid var(--color-blue);
}
.c-main-navigation--narrow .c-main-navigation__item:last-child {
	border-bottom:1px solid var(--color-blue);
}
.c-main-navigation__children .c-main-navigation__item:last-child {
	border-bottom:0;
}
.c-main-navigation--narrow .c-main-navigation__item.has-children {
	display:flex;
	flex-wrap:wrap;
}
.c-main-navigation--narrow .c-main-navigation__link {
	flex:1;
	padding:calc(var(--space-m) * 0.8) var(--space-m);
	color:var(--color-blue);
	font:var(--type-sz4-medium);
}
.c-main-navigation--narrow .c-main-navigation__link:hover,
.c-main-navigation--narrow .c-main-navigation__link:focus,
.c-main-navigation--narrow .c-main-navigation__link:active {
	background:none;
}
.c-main-navigation--narrow .c-main-navigation__toggle button {
	width:60px;
	height:100%;
	padding:0;
	border:0;
	border-left:1px solid var(--color-blue);
	background:none;
	color:var(--color-blue);
}
.c-main-navigation--narrow .c-main-navigation__toggle .c-icon {
	width:16px;
	height:16px;
}
.c-main-navigation--narrow .c-main-navigation__item.is-expanded > .c-main-navigation__children {
	height:auto;
}
.c-main-navigation--narrow .c-main-navigation__item.is-expanded {
	background-color:var(--color-blue) !important;
}
.c-main-navigation--narrow .c-main-navigation__item.is-expanded:first-child {
	border-color:var(--color-white);
}
.c-main-navigation--narrow .c-main-navigation__item.is-expanded > .c-main-navigation__link {
	color:var(--color-white);
}
.c-main-navigation--narrow .c-main-navigation__item.is-expanded > .c-main-navigation__toggle button {
	border-color:var(--color-white);
	color:var(--color-white);
}

/* Levels
 * Comments
----------------------------------------------------------------------------- */
.c-main-navigation__children .c-main-navigation__item {
	background-color:var(--color-grey-light);
}
.c-main-navigation__children .c-main-navigation__link {
	padding-left:var(--space-l);
}
.c-main-navigation__children .c-main-navigation__children .c-main-navigation__item {
	background-color:var(--color-grey-lightest);
}
.c-main-navigation__children .c-main-navigation__children .c-main-navigation__link {
	padding-left:var(--space-xl);
}
.c-main-navigation__children .c-main-navigation__children .c-main-navigation__children .c-main-navigation__item {
	background-color:var(--color-grey-light);
}
.c-main-navigation__children .c-main-navigation__children .c-main-navigation__children .c-main-navigation__link {
	padding-left:var(--space-xxl);
}
.c-main-navigation__children .c-main-navigation__children .c-main-navigation__children .c-main-navigation__children .c-main-navigation__item {
	background-color:var(--color-grey-lightest);
}
.c-main-navigation__children .c-main-navigation__children .c-main-navigation__children .c-main-navigation__children .c-main-navigation__link {
	padding-left:var(--space-xxxl);
}

@media screen and (min-width:768px) {
	.c-main-navigation ul {
		display:-ms-flexbox;
		display:flex;
	}
	.c-main-navigation ul.c-main-navigation__children {
		display:none;
	}
}

@media screen and (min-width:768px) and (max-width:1120px) {
	.c-main-navigation__link {
		padding:var(--space-s);
		font:var(--type-sz3-medium);
	}
}

/* Color theme
----------------------------------------------------------------------------- */
.c-main-navigation--green {
	background-color:var(--color-green);
}
.c-main-navigation--green .c-main-navigation__link:focus,
.c-main-navigation--green .c-main-navigation__link:hover {
	background-color:var(--color-green-dark);
	color:var(--color-white);
}
.c-main-navigation--green .c-main-navigation__item.is-active .c-main-navigation__link {
	background-color:var(--color-green-dark);
	color:var(--color-white);
}
.c-main-navigation--green.c-main-navigation--narrow {
	background-color:var(--color-white);
}
.c-main-navigation--green.c-main-navigation--narrow .c-main-navigation__item {
	border-top-color:var(--color-green);
}
.c-main-navigation--green.c-main-navigation--narrow .c-main-navigation__item:last-child {
	border-bottom-color:var(--color-green);
}
.c-main-navigation--green.c-main-navigation--narrow .c-main-navigation__link {
	color:var(--color-green);
}
.c-main-navigation--green.c-main-navigation--narrow .c-main-navigation__link:hover,
.c-main-navigation--green.c-main-navigation--narrow .c-main-navigation__link:focus,
.c-main-navigation--green.c-main-navigation--narrow .c-main-navigation__link:active {
	background:none;
}
.c-main-navigation--green.c-main-navigation--narrow .c-main-navigation__toggle button {
	border-left-color:var(--color-green);
	color:var(--color-green);
}
.c-main-navigation--green.c-main-navigation--narrow .c-main-navigation__item.is-expanded {
	background-color:var(--color-green) !important;
}
.c-main-navigation--green.c-main-navigation--narrow .c-main-navigation__item.is-expanded:first-child {
	border-color:var(--color-white);
}
.c-main-navigation--green.c-main-navigation--narrow .c-main-navigation__item.is-expanded > .c-main-navigation__link {
	color:var(--color-white);
}
.c-main-navigation--green.c-main-navigation--narrow .c-main-navigation__item.is-expanded > .c-main-navigation__toggle button {
	border-color:var(--color-white);
	color:var(--color-white);
}
