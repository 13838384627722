/* =============================================================================
 * Action Bar
 * postcss-bem-linter: define c-action-bar
============================================================================= */
.c-action-bar {
	/* display:none; */
}

@media screen and (min-width:768px) {
	.c-action-bar {
		display:block;
		margin-top:var(--space-s);
		margin-bottom:var(--space-s);
	}
}

@media print {
	.c-action-bar {
		display:block;
		max-width:1200px;
		width:100%;
		margin:var(--space-m) auto;
		padding:0 var(--space-m);
	}
}
