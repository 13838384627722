/* =============================================================================
 * Header
 * postcss-bem-linter: define header
============================================================================= */
.c-header {
	background-color:var(--color-white);
}
.c-header__ceiling {
	background-color:var(--color-grey-lightest);
}
.c-header__inner {
	padding:var(--space-m) var(--space-m);
}
.c-header__floor {
	background-color:var(--color-blue);
}
.c-header__logo {
	display:flex;
	align-items:center;
	margin:0;
	color:var(--color-blue);
	font:var(--type-sz4-bold);
}

.c-header__link {
	text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}

@media screen and (min-width:768px) {
	.c-header__ceiling-inner {
		display:-ms-flexbox;
		display:flex;
		-ms-flex-pack:justify;
		justify-content:space-between;
		-ms-flex-align:center;
		align-items:center;
	}
	.c-header__inner {
		display:-ms-flexbox;
		display:flex;
		-ms-flex-pack:justify;
		justify-content:space-between;
		-ms-flex-align:center;
		align-items:center;
	}
	.c-header__floor-inner {
		display:-ms-flexbox;
		display:flex;
		-ms-flex-pack:justify;
		justify-content:space-between;
		-ms-flex-align:center;
		align-items:center;
		padding-right:var(--space-m);
	}
	.c-header__logo {
		color:var(--color-blue);
		font:var(--type-sz7-bold);
	}
}
.c-header--slim {
	display:-ms-flexbox;
	display:flex;
	position:absolute;
	top:0;
	left:0;
	z-index:2;
	-ms-flex-pack:justify;
	justify-content:space-between;
	-ms-flex-wrap:space;
	flex-wrap:space;
	width:100%;
	height:70px;
	padding:var(--space-s);
	border-bottom:1px solid var(--color-blue);
}
.c-header--slim .c-header__drawer-toggler {
	display:-ms-flexbox;
	display:flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 6px;
	align-items:center;
	margin-left:var(--space-m);
	padding:0;
	border:0;
	background:none;
	color:var(--color-blue);
	font:var(--type-sz3-medium);
	line-height:1;
}

.c-header__drawer-toggler-icon--green svg {
	fill: var(--color-green) !important;
}
.c-header--slim .c-header__actions {
	display:flex;
	flex-wrap:nowrap;
}

@media screen and (min-width:768px) and (max-width:1120px) {
	.c-header__inner {
		padding-right:var(--space-s);
		padding-left:var(--space-s);
	}
}

@media screen and (max-width:767px) {
	.c-header--subsite {
		height:90px;
	}
	.js .c-header {
		opacity:0;
	}
	.js .c-header--stripped-down {
		opacity:1;
	}
	.js .c-header--slim {
		display:-ms-flexbox;
		display:flex;
		opacity:1;
	}
}
.c-header--mina-sidor .c-header__floor {
	background-color:var(--color-green);
}

@media print {
	.c-header__inner {
		max-width:1200px;
		width:100%;
		margin:var(--space-m) auto;
		padding:0 var(--space-m);
	}
	.c-header__ceiling,
	.c-header__floor {
		display:none;
	}
}
