/* =============================================================================
 * Drawer
============================================================================= */
.c-drawer {
	position:absolute;
	top:0;
	left:0;
	z-index:100;
	width:100%;
	height:0;
	overflow-x:hidden;
	overflow-y:hidden;
	transition:all 300ms;
}
.c-drawer.is-open {
	height:100vh;
	overflow-y:scroll;
	opacity:1;
	background-color: rgba(0, 0, 0, 0.5);
}
.c-drawer__content {
	background-color:#fff;
	background-color:var(--color-white);
	opacity:0;
	transition:all 300ms;
}
.c-drawer-is-open-fixed-menu .c-drawer__content {
	padding-bottom:var(--space-xxxl);
}
.c-drawer.is-open .c-drawer__content {
	opacity:1;
}

/* stylelint-disable */
.c-drawer-is-open-fixed-menu {
	position:fixed;
	height:100vh;
	overflow:hidden;
}

.c-drawer-is-open-fixed {
	position:fixed;
	height:100vh;
	overflow:hidden;
}
.c-drawer-is-open-fixed-menu .c-drawer {
	position:fixed;
}
.c-drawer-is-open-fixed-menu .c-header {
	position:fixed;
	top:0;
	left:0;
	z-index:101;
	width:100%;
}

/* Google Translate fix */
html[class*="translated"] .c-drawer-is-open-fixed-menu .c-drawer {
	top:39px;
	transform:translateZ(0);
}
