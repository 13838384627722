/* =============================================================================
 * Page details
 * postcss-bem-linter: define c-page-details
============================================================================= */
.c-page-details {
	position:relative;
	margin-top:var(--space-m);
	padding-top:var(--space-s);
}
.c-page-details::before {
	position:absolute;
	top:0;
	left:0;
	width:80px;
	height:1px;
	background-color:var(--color-grey-dark);
	content:"";
}
.c-page-details__item {
	color:var(--color-grey-dark);
}
.c-page-details__label {
	display:inline-block;
	font:var(--type-sz4);
}
.c-page-details__value {
	display:inline-block;
	margin:0;
	font:var(--type-sz4-medium);
}
