/* =============================================================================
 * Badge
 *
 * 1. Makes sure badge and text are aligned vertically.
 * 3. Makes sure text inside button can break
============================================================================= */
.c-badge {
	display:inline-block;
	padding:0.3em 0.6em 0.2em;
	border-radius:var(--space-m);
	background-color:var(--color-grey);
	color:var(--color-white);
	font-size:0.8em;
	line-height:1.2;
	text-transform:uppercase;
}
.c-badge--secondary {
	background-color:var(--color-purple);
}
.c-badge--tertiary {
	background-color:var(--color-green);
}
.c-badge--quartenary {
	background-color:var(--color-blue);
}
.c-badge--quinary {
	background-color:var(--color-grey-darkest);
}
