/* =============================================================================
 * Heading
============================================================================= */
.c-heading {
	display:flex;
	align-items:center;
	font:var(--type-sz4-medium);
}
.c-heading--s {
	margin:0 0 var(--vspace);
	font:var(--type-sz4-medium);
}
.c-heading--m {
	margin:0 0 var(--vspace);
	font:var(--type-sz4-medium);
}
.c-heading--l {
	margin:0 0 var(--vspace);
	font:var(--type-sz5-medium);
}
.c-heading--xl {
	margin:0 0 var(--vspace);
	font:var(--type-sz5-medium);
}
.c-heading--xxl {
	margin:0 0 var(--vspace);
	font:var(--type-sz6-medium);
}
.c-heading--no-spacing {
	margin:0;
}

.c-heading--m-bold {
	font: var(--type-sz4-bold);
}
.c-heading__icon {
	position:relative;
	flex:0 0 auto;
	margin-right:var(--space-xs);
}
.c-heading--s .c-heading__icon {
	width:14px;
	height:14px;
}
.c-heading--m .c-heading__icon {
	width:16px;
	height:16px;
}
.c-heading--l .c-heading__icon {
	width:20px;
	height:20px;
}
.c-heading--xl .c-heading__icon {
	width:24px;
	height:24px;
}
.c-heading--xxl .c-heading__icon {
	width:32px;
	height:32px;
}

@media (min-width:768px) {
	.c-heading {
		font:var(--type-sz5-medium);
	}
	.c-heading--s {
		font:var(--type-sz4-medium);
	}
	.c-heading--m {
		font:var(--type-sz5-medium);
	}
	.c-heading--l {
		font:var(--type-sz6-medium);
	}
	.c-heading--xl {
		font:var(--type-sz7-medium);
	}
	.c-heading--xxl {
		font:var(--type-sz8-medium);
	}
	.c-heading--s .c-heading__icon {
		width:16px;
		height:16px;
	}
	.c-heading--m .c-heading__icon {
		width:20px;
		height:20px;
	}
	.c-heading--l .c-heading__icon {
		width:24px;
		height:24px;
	}
	.c-heading--xl .c-heading__icon {
		width:32px;
		height:32px;
		margin-right:var(--space-s);
	}
	.c-heading--xxl .c-heading__icon {
		width:52px;
		height:52px;
		margin-right:var(--space-s);
	}
}
