/* =============================================================================
 * Services
============================================================================= */
.c-services:last-of-type {
	border:0;
}
.c-services__services {
	padding:var(--space-m) 0;
}
.c-services__services-title {
	font:var(--type-sz5-medium);
}
.c-services__e-services {
	margin-right:calc(var(--space-m) * -1);
	margin-left:calc(var(--space-m) * -1);
	padding:var(--space-m);
	border-bottom:1px solid var(--color-grey-light);
	background-color:var(--color-grey-lightest);
}
.c-services__e-services-title {
	display:flex;
	align-items:center;
	font:var(--type-sz4-medium);
}
.c-services__e-services-title .c-icon {
	margin-right:var(--space-xs);
	color:var(--color-green);
}

@media screen and (min-width:769px) {
	.c-services {
		display:flex;
		border-bottom:1px solid var(--color-grey-light);
	}
	.c-services__services {
		width:70%;
		padding:var(--space-l) var(--space-xl) var(--space-l) 0;
	}
	.c-services__e-services {
		width:30%;
		margin:0;
		padding:var(--space-l) var(--space-xl) var(--space-l);
		border:0;
		background-color:var(--color-grey-lightest);
	}
}
