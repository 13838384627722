/* =============================================================================
 * Form error
 *
 * postcss-bem-linter: define form-error
============================================================================= */
.c-form-error {
	margin-bottom:20px;
	padding:20px;
	border:6px solid var(--color-red);
}
.c-form-error__list {
	margin:0;
	padding:0;
	list-style:none;
}
.c-form-error__item {
	margin-bottom:12px;
}
.c-form-error__item:last-child {
	margin:0;
}
.c-form-error__item a {
	color:var(--color-red);
	font:var(--type-sz4-medium);
}
