/* =============================================================================
 * Compare table
 *
 * [1] Fix firefox border bug
============================================================================= */
.c-compare-table {
	max-width:1200px;
}
.c-compare-table table {
	margin:0;
	table-layout:fixed;
}
.c-compare-table th {
	font:var(--type-sz4-medium);
}
.c-compare-table thead th {
	position:relative;
	padding-right:calc(var(--space-s) + 24px);
	border-color:#fff;
	background:var(--color-purple);
	background-clip:padding-box; /* [1] */
	color:#fff;
}
.c-compare-table thead th:last-child {
	padding-right:var(--space-s);
	border-color:var(--color-purple);
}
.c-compare-table th,
.c-compare-table td {
	width:240px;
	padding:var(--space-s);
	border-right:1px solid var(--color-grey);
	border-bottom:none;
	vertical-align:middle;
}
.c-compare-table tbody tr:nth-child(odd) {
	background-color:var(--color-grey-lightest);
}
.c-compare-table__remove {
	position:absolute;
	top:10px;
	right:10px;
	width:24px;
	height:24px;
	padding:0;
	border:none;
	border-radius:100%;
	background:#fff;
	cursor:pointer;
}
.c-compare-table__remove .c-icon {
	display:block;
	width:50%;
	height:50%;
	margin:0 auto;
}
.c-compare-table .c-custom-select {
	color:#000;
}
.js .c-compare-table__submit {
	display:none;
}

@media screen and (max-width:540px) {
	.c-compare-table th,
	.c-compare-table td {
		width:180px;
	}
	.c-compare-table th {
		font:var(--type-sz3-medium);
	}
}
