/* =============================================================================
 * Read Speaker
 * postcss-bem-linter: define c-read-speaker
============================================================================= */
.c-read-speaker {
	margin-bottom:var(--space-m);
	padding:0 !important;
}

/* postcss-bem-linter: ignore */
.rsbtn_play:focus,
.rsbtn button:focus {
	outline:2px dotted currentcolor !important;
	outline:-webkit-focus-ring-color auto 5px !important;
}

/* postcss-bem-linter: ignore */
.rsbtn a:focus,
.rsbtn a:-moz-focusring,
.rsbtn button:-moz-focusring {
	outline:2px dotted currentcolor !important;
}

@media print {
	.c-read-speaker {
		display:none;
	}
}
