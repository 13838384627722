/* =============================================================================
 * Checkbox button
============================================================================= */
.c-checkbox-button {
	display:inline-flex;
	position:relative;
}
.c-checkbox-button--block {
	display:block;
}
.c-checkbox-button__input input {
	position:absolute;
	top:0;
	left:0;
	z-index:1;
	width:100%;
	height:100%;
	opacity:0;
}
.c-checkbox-button__icons .c-icon {
	position:absolute;
	top:50%;
	right:var(--space-s);
	width:20px;
	height:20px;
	transform:translateY(-50%);
}
.c-checkbox-button_label {
	margin:0;
	padding:var(--space-xs) calc(var(--space-m) + 20px) var(--space-xs) var(--space-m);
	border:1px solid #767f8d;
	border-radius:var(--space-m);
	background-color:var(--color-white);
	color:var(--color-black);
	font:var(--type-sz3-medium);
	line-height:inherit;
	vertical-align:middle;
	text-align:left;
	text-decoration:none;
	transition:all 200ms;
}
.c-checkbox-button--block .c-checkbox-button_label {
	display:block;
}
.c-checkbox-button__input input:hover ~ label,
.c-checkbox-button__input input:focus ~ label {
	text-decoration:underline;
}
.c-checkbox-button__input input:checked ~ .c-checkbox-button__icons .c-checkbox-button__unchecked-icon,
.c-checkbox-button__input input ~ .c-checkbox-button__icons .c-checkbox-button__checked-icon {
	display:none;
}
.c-checkbox-button__input input:checked ~ .c-checkbox-button__icons .c-checkbox-button__checked-icon {
	display:inline-block;
}
.c-checkbox-button__input input:checked ~ label {
	border-color:var(--color-purple);
	background:var(--color-purple);
	color:#fff;
}
.c-checkbox-button__input input:checked ~ .c-checkbox-button__icons svg {
	fill:#fff;
}
