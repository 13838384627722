/* =============================================================================
 * Button
 *
 * 1. Needed to reset any styles set on a:visited in case the `c-button` class
 *    is used on `a` elements.
 * 2. Makes sure button, input and a elements are aligned vertically.
 * 3. Makes sure text inside button can break
============================================================================= */
.c-button,
.c-button:visited /* 1 */ {
	display:inline-flex;
	position:relative;
	align-items:center;
	width:auto;
	overflow:hidden;
	padding:10px var(--space-s);
	border:1px solid #767f8d;
	border-radius:var(--space-m);
	background-color:var(--color-white);
	color:var(--color-black);
	font:var(--type-sz3-medium);
	line-height:1.5;
	vertical-align:middle; /* 2 */
	text-align:left;
	text-decoration:none;
	word-wrap:break-word;
	word-break:break-word;
	transition:all 200ms;
	overflow-wrap:break-word;
	text-shadow: none;
}
.c-button:hover,
.c-button:focus {
	border-color:var(--color-blue);
	background-color:var(--color-blue);
	color:var(--color-white);
}
.c-button[disabled] {
	background-color:var(--color-grey-light);
	color:currentColor;
	cursor:not-allowed;
}

.c-button--pdf::before {
	content:url(../../images/icons/document-blank.svg);
	background-repeat: no-repeat;
	height: 18px;
	width: 15px;
	margin-right: 12px;
}

.c-button > .c-icon {
	display:-ms-flexbox;
	display:flex;
	flex:0 0 auto;
	margin-right:var(--space-xs);
	margin-left:calc(var(--space-xxs) * -1);
	color:var(--color-blue);
	transition:all 200ms;
}
.c-button:hover > .c-icon,
.c-button:focus > .c-icon {
	color:var(--color-white);
}
.c-button[disabled] > .c-icon,
.c-button[disabled]:hover > .c-icon,
.c-button[disabled]:focus > .c-icon {
	color:var(--color-grey);
}

/* Use when text inside c-button has to break */
.c-button__text {
	display:block; /* 3 */
	flex:1 1 auto; /* 3 */
	min-width:0; /* 3 */
	width:100%;
}

/* used for buttons outside main container */
.c-button__container--right {
	width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 0 var(--space-m);
}

@media (min-width:600px) {
	.c-button__container--right .c-button{
		max-width: 35%;
	}
}
/* Button With arrow icon
 ---------------------------------------------------------------------------- */
.c-button--arrow {
	padding-right:calc(var(--space-s) + 24px);
}

/* .c-button--arrow-left {
	padding-right:calc(var(--space-s) + 24px);
} */

.c-button > .c-button__arrow {
	display:-ms-flexbox;
	display:flex;
	width:24px;
	height:24px;
	margin-right:0;
	margin-left:auto;
	border-radius:50%;
	background-color:var(--color-blue);
	color:var(--color-white);
	content:"";
	transition:all 200ms;
	transform:translateX(calc(var(--space-xs) + 24px));
}
.c-button > .c-button__arrow--left {
	display:-ms-flexbox;
	display:flex;
	width:24px;
	height:24px;
	margin-right:0;
	margin-left:auto;
	border-radius:50%;
	background-color:var(--color-blue);
	color:var(--color-white);
	content:"";
	transition:all 200ms;
	margin-right:calc(var(--space-s) + 24px);
}
.c-button:hover > .c-button__arrow,
.c-button:hover > .c-button__arrow--left,
.c-button:focus > .c-button__arrow,
.c-button:focus > .c-button__arrow--left {
	background-color:var(--color-white);
	color:var(--color-blue);
}
.c-button[disabled] > .c-button__arrow,
.c-button[disabled]:hover > .c-button__arrow,
.c-button[disabled]:focus > .c-button__arrow,
.c-button[disabled] > .c-button__arrow--left,
.c-button[disabled]:hover > .c-button__arrow--left,
.c-button[disabled]:focus > .c-button__arrow--left {
	background-color:var(--color-grey-lightest);
	color:var(--color-grey);
}

/* Inverted
 ---------------------------------------------------------------------------- */
.c-button--inverted,
.c-button--inverted:visited /* 1 */ {
	border-color:var(--color-blue);
	background-color:var(--color-blue);
	color:#fff;
}
.c-button--inverted:hover,
.c-button--inverted:focus {
	background-color:var(--color-blue-dark);
}
.c-button--inverted[disabled],
.c-button--inverted[disabled]:visited /* 1 */ {
	border-color:#767f8d;
}
.c-button--inverted[disabled]:hover,
.c-button--inverted[disabled]:focus {
	background-color:var(--color-grey-light);
}
.c-button--inverted > .c-icon {
	color:currentColor;
}
.c-button--inverted > .c-button__arrow,
.c-button--inverted > .c-button__arrow--left {
	background-color:#fff;
	color:var(--color-blue);
}


/* Secondary
 ---------------------------------------------------------------------------- */
.c-button--secondary:hover,
.c-button--secondary:focus {
	border-color:var(--color-green);
	background-color:var(--color-green);
	color:var(--color-white);
}
.c-button--secondary[disabled] {
	background-color:var(--color-grey);
	color:var(--color-white);
}
.c-button--secondary[disabled]:hover,
.c-button--secondary[disabled]:focus {
	border-color:#767f8d;
}
.c-button--secondary .c-icon {
	color:var(--color-green);
}
.c-button--secondary .c-button__arrow,
.c-button--secondary .c-button__arrow--left {
	background-color:var(--color-green);
	color:var(--color-white);
}
.c-button--secondary:hover .c-button__arrow,
.c-button--secondary:focus .c-button__arrow,
.c-button--secondary:hover .c-button__arrow--left,
.c-button--secondary:focus .c-button__arrow--left {
	background-color:var(--color-white);
	color:var(--color-green);
}


/* Secondary Inverted
 ---------------------------------------------------------------------------- */
.c-button--secondary.c-button--inverted,
.c-button--secondary.c-button--inverted:visited /* 1 */ {
	border-color:var(--color-green);
	background-color:var(--color-green);
	color:var(--color-white);
}
.c-button--secondary.c-button--inverted:hover,
.c-button--secondary.c-button--inverted:focus {
	background-color:var(--color-green-dark);
}
.c-button--secondary.c-button--inverted[disabled] {
	border-color:#767f8d;
	background-color:var(--color-grey-light);
	color:currentColor;
}
.c-button--secondary.c-button--inverted .c-icon {
	color:currentColor;
}
.c-button--secondary.c-button--inverted[disabled] > .c-icon,
.c-button--secondary.c-button--inverted[disabled]:hover > .c-icon,
.c-button--secondary.c-button--inverted[disabled]:focus > .c-icon {
	color:var(--color-grey);
}
.c-button--secondary.c-button--inverted .c-button__arrow,
.c-button--secondary.c-button--inverted .c-button__arrow--left  {
	background-color:var(--color-white);
	color:var(--color-green);
}
.c-button--secondary.c-button--inverted:hover .c-button__arrow,
.c-button--secondary.c-button--inverted:focus .c-button__arrow,
.c-button--secondary.c-button--inverted:hover .c-button__arrow--left,
.c-button--secondary.c-button--inverted:focus .c-button__arrow--left {
	background-color:var(--color-white);
	color:var(--color-green);
}
.c-button--secondary.c-button--inverted[disabled] > .c-button__arrow,
.c-button--secondary.c-button--inverted[disabled]:hover > .c-button__arrow,
.c-button--secondary.c-button--inverted[disabled]:focus > .c-button__arrow,
.c-button--secondary.c-button--inverted[disabled] > .c-button__arrow--left,
.c-button--secondary.c-button--inverted[disabled]:hover > .c-button__arrow--left,
.c-button--secondary.c-button--inverted[disabled]:focus > .c-button__arrow--left {
	background-color:var(--color-grey-lightest);
	color:var(--color-grey);
}


/* Block
 ---------------------------------------------------------------------------- */
.c-button--block {
	width:100%;
}

/* CSS for Rek-ai button list
 ---------------------------------------------------------------------------- */
.rek-prediction__list {
	list-style: none;
    display: flex;
    justify-content: flex-start;
    column-gap: var(--space-s);
    flex-wrap: wrap;
	padding: 0;
}