/* =============================================================================
 * Pagination
 * postcss-bem-linter: define pagination
============================================================================= */
.c-pagination__label {
	display:block;
	margin-bottom:calc(30px / 2);
	margin-bottom:calc(var(--vspace) / 2);
	text-align:center;
}
.c-pagination ul {
	margin:0;
	padding:0;
	list-style:none;
	text-align:center;
}
.c-pagination li {
	display:inline-block;
	margin:0;
}
.c-pagination a {
	display:inline-block;
	min-width:16px;
	text-align:center;
}


/* Current page
----------------------------------------------------------------------------- */
.c-pagination__current {
	font-weight:bold;
}
