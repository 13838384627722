/* =============================================================================
 * Custom checkbox
 *
 * 1. We use flex to ensure that if the label text wraps, it does not wrap below
 *    the checkbox.
 *
 * postcss-bem-linter: define custom-checkbox
============================================================================= */
.c-custom-checkbox {
	display:-ms-flexbox;
	display:flex; /* 1 */
	position:relative;
	flex-wrap:wrap;
	-ms-flex-align:center;
	align-items:center;
}
.c-custom-checkbox__input {
	display:inline-block;
	position:relative;
	-ms-flex:none;
	flex:none; /* 1 */
	width:32px;
	height:32px;
	vertical-align:middle;
}
.c-custom-checkbox input {
	position:absolute;
	top:0;
	left:0;
	z-index:1;
	width:100%;
	height:100%;
	opacity:0;
}
.c-custom-checkbox__input-graphic {
	position:absolute;
	top:0;
	right:0;
	bottom:0;
	left:0;
	border:2px solid #ccc;
	background:#fff;
}
.c-custom-checkbox__mark {
	display:-ms-flexbox;
	display:flex;
	position:absolute;
	top:2px;
	bottom:0;
	left:-1px;
	-ms-flex-pack:center;
	justify-content:center;
	-ms-flex-align:center;
	align-items:center;
	width:32px;
	font-size:24px;
	opacity:0;
}
.c-custom-checkbox label {
	margin-top:4px;
	margin-bottom:0;
	margin-left:0.5em;
}

.c-custom-checkbox__container {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex-wrap: wrap;
}

.c-custom-checkbox__box-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	margin: 0px 40px 20px 0px;
	min-width: 25%;
}

/* Checked
----------------------------------------------------------------------------- */
.c-custom-checkbox input:checked + .c-custom-checkbox__input-graphic .c-custom-checkbox__mark {
	opacity:1;
}


/* Hover
----------------------------------------------------------------------------- */
.c-custom-checkbox input:not([disabled]):hover + .c-custom-checkbox__input-graphic {
	border-color:#666;
}


/* Focus
----------------------------------------------------------------------------- */
.c-custom-checkbox input:not([disabled]):focus + .c-custom-checkbox__input-graphic {
	border-color:#666;
}


/* Disabled
----------------------------------------------------------------------------- */
.c-custom-checkbox input[disabled] + .c-custom-checkbox__input-graphic {
	opacity:0.5;
}
