/* =============================================================================
 * Card event
 * postcss-bem-linter: define c-card-event
============================================================================= */
.c-card-event {
	position:relative;
	width: 100%;
	min-width: 156px;
	background-color:var(--color-white);
	box-shadow: 0px 4px 10px 0px rgba(0, 36, 60, 0.06);
	overflow: hidden;
	transition: box-shadow 150ms;
}

.c-card-event:hover {
	box-shadow:0 0 var(--space-m) rgba(0, 0, 0, 0.1);
}

.c-card-event:focus,
.c-card-event:active {
	box-shadow: 0px 4px 10px 0px rgba(0, 36, 60, 0.06);
}

.c-card-event__inner { 
	height: 100%;
	width: 100%;
}

.c-card-event__inner:hover {
	cursor: pointer;
}

.c-card-event a {
	color: var(--color-black);
	text-decoration: none;
}

.c-card-event__img-container {
	position: relative;
}

.c-card-event__img-container img {
	width: 100%;
	aspect-ratio: 347/225;
	object-fit:cover;
}

.c-card-event__quick-info {
	position: absolute;
	right: 0;
	bottom: 0;
	background-color: var(--color-grey-lightest);
	border-radius: 20px 0px 0px 0px;
	color: var(--color-blue);
}

.c-card-event__quick-info span {
	font-size: 12px;
	padding: 9px 16px 9px 20px;
}

.c-card-event__quick-info span:nth-child(2) {
	padding-left: 0px;
}

.c-card-event__quick-info--event-time::before {
	display:inline-block;
	width:12px;
	height:12px;
	margin-right:4px;
	background:url("/gui/images/icons/clock.svg") no-repeat 0 0;
	background-size:auto auto;
	background-size:12px 12px;
	content:"";
	vertical-align:-2px;
}

.c-card-event__quick-info--event-date::before {
	display:inline-block;
	width:12px;
	height:12px;
	margin-right:4px;
	background:url("/gui/images/icons/calendar.svg") no-repeat 0 0;
	background-size:auto auto;
	background-size:12px 12px;
	content:"";
	vertical-align:-2px;
}

.c-card-event__event-text {
	margin-bottom: 10px;
}

.c-card-event__content-container {
	padding: 24px 20px;
}

.c-card-event__link-container {
	padding: 32px 20px;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.c-card-event__link-container p {
	margin-bottom: 20px;
}

.c-card-event__link-container a:not(.c-button) {
	color: var(--color-blue);
}

.c-card-event__link-container a:not(.c-button):hover {
	text-decoration: underline
}

.c-card-event__link-container:hover {
text-decoration: none !important;
}

.c-card-event__link-container-button {
	display: flex;
	justify-content: flex-end;
}

.c-card-event__news-text {
	font-size: 20px;
	font-weight: 500;
}

.c-card-event .c-button {
	/* position: absolute;
	bottom: 32px;
	right: 16px; */
}

.c-card-event__card-heading {
	font-size: 20px;
	margin-bottom: 16px;
}

.c-card-event__event-detail-heading {
	display: inline-block;
	margin-bottom: 0;
}

@media screen and (min-width:769px) {
	.c-card-event {
		max-width: 348px;
	}
}