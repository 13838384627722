/* =============================================================================
 * Autocomplete
 *
 * This is based on the CSS that is included with
 * https://github.com/alphagov/accessible-autocomplete. Class names are injected
 * by the script and cannot currently be (easily) changed.
 *
 * postcss-bem-linter: define autocomplete
============================================================================= */
.autocomplete__hint,
.autocomplete__input {
	width:100%;
	margin-bottom:0;
	-webkit-appearance:none;
}
.autocomplete__input {
	position:relative;
}
.autocomplete__hint {
	position:absolute;
	color:#bfc1c3;
}
.autocomplete__input--show-all-values {
	padding:6px 36px 6px 6px;
	padding:var(--space-xs) var(--space-l) var(--space-xs) var(--space-xs);
	cursor:pointer;
}
.autocomplete__dropdown-arrow-down {
	display:inline-block;
	position:absolute;
	top:12px;
	top:var(--space-s);
	right:6px;
	right:var(--space-xs);
	z-index:-1;
	width:24px;
	height:24px;
}
.autocomplete__menu {
	max-height:342px;
	overflow-x:hidden;
	margin:0 auto;
	padding:0;
	border:1px solid #d9d9d9;
	border:1px solid var(--color-grey-light);
	background-color:#fff;
	color:#000;
	font:var(--type-sz4);
	
}
.autocomplete__menu--visible {
	display:block;
}
.autocomplete__menu--hidden {
	display:none;
}
.autocomplete__menu--overlay {
	position:absolute;
	top:34px;
	right:-2px;
	left:-2px;
	z-index:100;
	padding-top: 32px;
	border-radius: 0 0 30px 30px;
}

.c-header .autocomplete__menu--overlay {
	right:-1px;
}

.autocomplete__menu--inline {
	position:relative;
}
.autocomplete__option {
	display:block;
	position:relative;
	margin:0;
	border-bottom:1px solid #bfc1c3;
	cursor:pointer;
	text-shadow: none;
}

/* stylelint-disable plugin/selector-bem-pattern */
.autocomplete__option > * {
	pointer-events:none;
}
.autocomplete__option:first-of-type {
	border-top-width:0;
}
.autocomplete__option:last-of-type {
	border-radius: 0 0 30px 30px;
}
.autocomplete__option:last-of-type {
	border-bottom-width:0;
}
.autocomplete__option--focused,
.autocomplete__option:hover {
	outline:none;
	border-color:var(--color-blue);
	background-color:var(--color-blue);
	color:#fff;
}
.autocomplete__option--focused a,
.autocomplete__option:hover a {
	color:#fff;
}
.autocomplete__option--no-results {
	background-color:#fafafa;
	color:#646b6f;
	cursor:not-allowed;
}
.autocomplete__hint,
.autocomplete__option {
	padding:12px;
	padding:var(--space-s);
}
.autocomplete__option mark {
	background-color:inherit;
	color:inherit;
}
.autocomplete__option mark,
.autocomplete__option strong {
	font:var(--type-sz4-bold);
}

/* To stop siteimprove error */
.autocomplete__wrapper div[role="status"] span {
	display:none;
}
