/* =============================================================================
 * Hero
 * postcss-bem-linter: define c-hero
============================================================================= */
.c-hero {
	width:100%;
	height:260px;
	background-position:center;
	background-size:cover;
}
.c-hero--big {
	height:380px;
}
.c-hero--iframe {
	min-height:420px;
	height:calc(100vh - 640px);
	padding:0 var(--space-m);
}
.c-hero--iframe iframe {
	width:100%;
	height:100%;
	border:none;
}

@media screen and (max-width:767px) {
	.c-hero--big {
		height:300px;
	}
}
