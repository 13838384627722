/* =============================================================================
 * additional navigation
 * postcss-bem-linter: define additional-navigation
============================================================================= */
.c-additional-navigation {
	background-color:#f4f4f4;
	background-color:var(--color-grey-lightest);
	color:#202020;
	color:var(--color-black);
}
.c-additional-navigation ul {
	margin:0;
	padding:0;
	list-style:none;
}
.c-additional-navigation__item {
	display:inline-block;
	position:relative;
	margin:0;
}
.c-additional-navigation__link {
	display:block;
	padding:12px 24px;
	padding:var(--space-s) var(--space-m);
	color:inherit;
	font:13px/18px "Gill Sans Medium", Arial, sans-serif;
	font:var(--type-sz2-medium);
	line-height:1;
	text-decoration:none;
	transition:all 200ms;
}
.c-additional-navigation__link:visited {
	color:inherit;
}
.c-additional-navigation__link:focus,
.c-additional-navigation__link:hover,
.c-additional-navigation__item.is-active .c-additional-navigation__link {
	color:#0061a1;
	color:var(--color-blue);
}
.c-additional-navigation__button {
	margin:0;
	padding:var(--space-s) var(--space-m);
	border:none;
	background-color:transparent;
	color:inherit;
	font:var(--type-sz2-medium);
	line-height:1;
	text-align:left;
	-webkit-appearance:none;
}

/* Google Translate fix */
.c-additional-navigation__button font {
	pointer-events:none;
}

@media screen and (min-width:768px) {
	.c-additional-navigation ul {
		display:-ms-flexbox;
		display:flex;
	}
}

@media screen and (max-width:767px) {
	.c-additional-navigation {
		background:#fff;
	}
	.c-additional-navigation__translate {
		padding:var(--space-m) var(--space-m) 0 var(--space-m);
	}
}

@media print {
	.c-additional-navigation {
		display:none;
	}
}
