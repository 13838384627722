/* =============================================================================
 * Footer
 * postcss-bem-linter: define footer
============================================================================= */
.c-footer {
	background-color:#70445f;
	background-color:var(--color-purple);
	color:#fff;
	font:var(--type-sz3);
}
.c-footer a {
	color:var(--color-white);
	text-decoration:none;
}
.c-footer a:hover {
	text-decoration:underline;
}
.c-footer__inner {
	padding:var(--space-s) var(--space-s);
}
.c-footer__section {
	padding:var(--space-m) 0;
	border-bottom:1px solid var(--color-white);
}
.c-footer__section:last-child {
	border:0;
}
.c-footer__title {
	margin-bottom:var(--space-s);
	font:var(--type-sz5-medium);
}

@media screen and (min-width:992px) {
	.c-footer {
		overflow:hidden;
		background-color:#70445f;
		background-color:var(--color-purple);
		color:#fff;
		font:var(--type-sz3);
	}
	.c-footer a {
		color:var(--color-white);
		text-decoration:none;
	}
	.c-footer a:hover {
		text-decoration:underline;
	}
	.c-footer__inner {
		display:flex;
		margin-right:calc(var(--space-xxl) * -1);
		margin-left:calc(var(--space-xxl) * -1);
		padding-top:var(--space-xl);
		padding-bottom:var(--space-xl);
	}
	.c-footer__section {
		width:33.3334%;
		padding:var(--space-s) var(--space-xxl);
		border-right:1px solid var(--color-white);
		border-bottom:0;
	}
	.c-footer__section:last-child {
		border:0;
	}
	.c-footer__title {
		margin-bottom:var(--space-s);
		font:var(--type-sz5-medium);
	}
}

@media print {
	.c-footer {
		display:none;
	}
}
