/* =============================================================================
 * Page divider
============================================================================= */
.c-page-divider {
	margin-top:var(--space-m);
	padding-top:var(--space-m);
	border-top:1px solid var(--color-grey-light);
}
.c-page-divider--extra-space {
	margin-top:var(--space-l);
	padding-top:var(--space-l);
}
