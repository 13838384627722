/* =============================================================================
 * Post list
 * postcss-bem-linter: define c-post-list
============================================================================= */
.c-post-list__list {
	padding:0;
	list-style:none;
}
.c-post-list__item {
	display:flex;
	position:relative;
	flex-wrap:wrap;
	align-items:flex-start;
	margin-bottom:var(--space-m);
	padding-bottom:var(--space-m);
}
.c-post-list--spacious .c-post-list__item {
	margin-bottom:var(--space-l);
	padding-bottom:var(--space-l);
}
.c-post-list__item::after {
	position:absolute;
	bottom:0;
	left:0;
	width:120px;
	height:1px;
	background-color:var(--color-grey-light);
	content:"";
}
.c-post-list__item--large-divider::after {
	width:100%;
}
.c-post-list__item:last-child {
	padding-bottom:0;
}
.c-post-list__item:last-child::after {
	display:none;
}
.c-post-list__details {
	margin-bottom:0;
	padding-left:0;
	list-style:none;
	font:var(--type-sz3);
}
.c-post-list__details li {
	display:flex;
	align-items:center;
	margin-bottom:0;
}
.c-post-list__details span:first-child {
	margin-right:6px;
	margin-bottom:6px;
}
.c-post-list__wrapper {
	flex:1 1 70%;
}
.c-post-list__image {
	flex:0 1 auto;
	width:calc(30% - var(--space-s));
	margin-left:var(--space-s);
}
.c-post-list__datewrapper .c-post-list__date {
	display:inline-block;
	font:var(--type-sz4);
}
.c-post-list__date {
	display:block;
	font:var(--type-sz3);
}
.c-post-list__title {
	margin-bottom:var(--space-s);
}
.c-post-list__link,
.c-post-list__link:visited {
	color:var(--color-blue);
	font:var(--type-sz5-medium);
	text-decoration:none;
}
.c-post-list__content {
	margin-bottom:0;
}
.js .c-post-list__submit {
	display:none;
}
.c-post-list__meta {
	flex:1 1 auto;
	width:100%;
	margin:0;
	padding-top:var(--space-s);
	font:var(--type-sz3-medium);
}
.c-post-list__meta a {
	text-decoration:none;
}

/* Label value modifier */
.c-post-list__item--label-value .c-post-list__label-value {
	margin-top:var(--space-s);
	padding-left:var(--space-m);
	border-left: 1px solid var(--color-blue);
}
@media screen and (min-width:1030px) {
	.c-post-list__item--label-value .c-post-list__wrapper {
		display: flex;
		flex-wrap: wrap;
	}
	.c-post-list__item--label-value .c-post-list__title {
		width: 100%;
		margin-bottom:var(--space-m);
	}
	.c-post-list__item--label-value .c-post-list__content {
		width: calc(50%);
		padding-right:var(--space-m);
	}
	.c-post-list__item--label-value .c-post-list__label-value {
		align-self: flex-start;
		margin-top:0;
	}
}

@media screen and (min-width:768px) {
	.c-post-list--search {
		margin-top:var(--space-m);
	}
}

@media screen and (max-width:400px) {
	.c-post-list__wrapper {
		flex:1 1 65%;
	}
	.c-post-list__image {
		flex:0 1 auto;
		width:calc(35% - var(--space-s));
	}
}
