.c-chart-percentage {
    text-align: center;
    margin: auto;
    width: 100%;
}
  
  .c-chart-percentage__circular-chart {
    display: block;
    margin: 32px auto 20px;
    max-width: 80%;
    max-height: 250px;
  }
  
  .c-chart-percentage__circle-bg {
    fill: none;
    stroke: var(--color-grey-lighter);
    stroke-width: 2.2;
  }
  
  .c-chart-percentage__circle {
      fill: none;
      stroke-width: 2.2;
      stroke-linecap: round;
      animation: progress 1s ease-out forwards;
      stroke: var(--color-blue);
    }
    
    .c-chart-percentage__circle--warning {
        stroke: #C63131;
        fill: none;
      stroke-width: 2.2;
      stroke-linecap: round;
      animation: progress 1s ease-out forwards;
    }
  
  @keyframes progress {
    0% {
      stroke-dasharray: 0 100;
    }
  }
  
  /* .c-chart-percentage__circular-chart.orange .c-chart-percentage__circle {
    stroke: #ff9f00;
  } */
  
  
  .c-chart-percentage__percentage {
    fill: #000000;
    font-size: 0.5em;
    text-anchor: middle;
  }

  .c-chart-percentage__percentage-text {
    fill: #000000;
    font-size: 0.15em;
    text-anchor: middle;
  }

  .c-chart-percentage__chart-name {
    font-size: 24px;
    margin: 0;
  }
  .c-chart-percentage__location-text {
    font-size: 18px;
  } 