/* =============================================================================
 * Custom select
 *
 * 1. Hide dropdown arrow in IE10+.
 * 2. Background styling is put on a separate element placed after the actual
 *    select in the DOM to be able to act on states of the select.
 * 3. Make sure actual select is above the fake select.
 * 4. This should match the height of text fields.
 *
 * postcss-bem-linter: define custom-select
============================================================================= */
.c-custom-select--inline {
	display:flex;
	align-items:baseline;
}
.c-custom-select--inline label {
	flex:0 1 auto;
	margin-right:var(--space-s);
}
.c-custom-select--inline .c-custom-select__select {
	flex:1 1 auto;
}
.c-custom-select__select {
	display:-ms-flexbox;
	display:flex;
	position:relative;
	height:48px; /* 4 */
	line-height:1;
	vertical-align:middle;
}
.c-custom-select select {
	position:relative;
	z-index:2; /* 3 */
	width:100%;
	height:auto;
	padding:0 50px 0 10px;
	border:none;
	background:transparent;
	-webkit-appearance:none;
	-moz-appearance:none;
}
.c-custom-select select::-ms-expand {
	display:none; /* 1 */
}
.c-custom-select__select-graphic {
	position:absolute; /* 2 */
	top:0;
	right:0;
	bottom:0;
	left:0;
	z-index:1; /* 3 */
	overflow:hidden;
	border:2px solid #ccc;
	background:#fff;
}
.c-custom-select__arrow {
	display:-ms-flexbox;
	display:flex;
	position:absolute;
	top:0;
	right:0;
	bottom:0;
	-ms-flex-pack:center;
	justify-content:center;
	-ms-flex-align:center;
	align-items:center;
	width:46px;
	border-left:2px solid #ccc;
	font-size:34px;
}


/* Hover and focus effect
 *
 * 1. Remove the native focus outline where possible.
 * 2. Make the focus ring Firefox adds transparent = invisible.
 * 3. [2] also affects the text so we use a text shadow with no spread or blur
 *    to simulate the text. Change the color value as needed.
 * 4. IE 11- uses white text on a blue background for focused select elements,
 *    so we make the background transparent and inherit the color.
----------------------------------------------------------------------------- */
.c-custom-select select:focus {
	outline:none; /* 1 */
}
.c-custom-select select:-moz-focusring {
	color:transparent; /* 2 */
	text-shadow:0 0 #000; /* 3 */
}
.c-custom-select select:focus::-ms-value {
	background:transparent; /* 4 */
	color:inherit; /* 4 */
}
.c-custom-select select:hover + .c-custom-select__select-graphic,
.c-custom-select select:hover + .c-custom-select__select-graphic .c-custom-select__arrow,
.c-custom-select select:focus + .c-custom-select__select-graphic,
.c-custom-select select:focus + .c-custom-select__select-graphic .c-custom-select__arrow {
	border-color:#666;
}


/* Disabled
----------------------------------------------------------------------------- */
.c-custom-select select[disabled] + .c-custom-select__select-graphic {
	opacity:0.5;
}
.c-custom-select select[disabled]:hover + .c-custom-select__select-graphic,
.c-custom-select select[disabled]:hover + .c-custom-select__select-graphic .c-custom-select__arrow {
	border-color:#ccc;
}
