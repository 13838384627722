/* =============================================================================
 * Entry navigation
 * postcss-bem-linter: define entry-navigation
============================================================================= */
.c-entry-navigation {
	background-color:#f4f4f4;
	background-color:var(--color-grey-lightest);
	color:#0061a1;
	color:var(--color-blue);
}
.c-entry-navigation ul {
	margin:0;
	padding:0;
	list-style:none;
}
.c-entry-navigation__item {
	display:inline-block;
	margin:0;
}
.c-entry-navigation__link {
	display:flex;
	align-items:center;
	padding:12px;
	padding:calc(var(--space-m) * 0.8) var(--space-s);
	color:inherit;
	font:15px/24px "Gill Sans Medium", Arial, sans-serif;
	font:var(--type-sz3-medium);
	line-height:1;
	text-decoration:none;
	transition:all 200ms;
}
.c-entry-navigation__link:visited {
	color:inherit;
}
.c-entry-navigation__link:focus,
.c-entry-navigation__link:hover {
	color:#202020;
	color:var(--color-black);
}
.c-entry-navigation__item.is-active .c-entry-navigation__link {
	background-color:#fff;
	background-color:var(--color-white);
	color:var(--color-black);
}

@media screen and (min-width:340px) {
	.c-entry-navigation__link {
		padding:var(--space-m) var(--space-m);
	}
}

@media screen and (min-width:768px) {
	.c-entry-navigation ul {
		display:-ms-flexbox;
		display:flex;
	}
	.c-entry-navigation__link {
		padding:12px 24px;
		padding:var(--space-s) var(--space-m);
		font:18px/30px "Gill Sans Medium", Arial, sans-serif;
		font:var(--type-sz4-medium);
	}
}
.c-entry-navigation--narrow {
	background-color:var(--color-blue);
}
.c-entry-navigation--narrow .c-entry-navigation__link {
	color:var(--color-white);
}
.c-entry-navigation--narrow .c-entry-navigation__item.is-active .c-entry-navigation__link {
	color:var(--color-blue);
}
