/* =============================================================================
 * Label value list
============================================================================= */
.c-label-value-list {
	margin:0;
}
.c-label-value-list__item {
	display:block;
}
@media print {
	.c-label-value-list__item {
		page-break-inside: avoid;
	}
}
.c-label-value-list__label {
	display:inline-block;
	margin-right:var(--space-xxs);
	font-weight:normal;
	font-family:"Gill Sans Medium", Arial, sans-serif;
}
.c-label-value-list__label .c-icon {
	position:relative;
	top:-2px;
	color:var(--color-blue);
}
.c-label-value-list__label--subtle {
	color:var(--color-grey-dark);
	font-weight:normal;
}
.c-label-value-list__value {
	display:inline-block;
	margin:0;
}
.c-label-value-list__value--temp::before {
	display:inline-block;
	width:17px;
	height:20px;
	margin-right:8px;
	background:url("/gui/images/icons/thermometer.svg") no-repeat 0 0;
	background-size:auto auto;
	background-size:17px 20px;
	content:"";
	vertical-align:-3px;
}

.c-label-value-list__value p {
	margin-bottom: 6px;
}
.c-label-value-list__value p:last-child {
	margin:0;
}
.c-label-value-list__value--align-center {
	text-align:center;
}
.c-label-value-list__item--divider:not(:last-of-type) {
	border-bottom: 1px solid #ccc;
}
.c-label-value-list__item--divider {
	padding-bottom:12px;
	margin-bottom:12px;
}

/* Rows
----------------------------------------------------------------------------- */
.c-label-value-list--rows .c-label-value-list__label,
.c-label-value-list--rows .c-label-value-list__value {
	display:block;
}

/* Flex
----------------------------------------------------------------------------- */
.c-label-value-list--flex .c-label-value-list__item {
	display:flex;
	flex-wrap:wrap;
	align-items:baseline;
}
.c-label-value-list--flex .c-label-value-list__label {
	flex:1 1 calc(50% - var(--space-xxs));
	min-width:120px;
}
.c-label-value-list--flex .c-label-value-list__value {
	flex:1 1 50%;
}

/* Flex equal width
----------------------------------------------------------------------------- */
.c-label-value-list--flex-equal .c-label-value-list__item {
	display:flex;
	flex-wrap:wrap;
	align-items:baseline;
}
.c-label-value-list--flex-equal .c-label-value-list__label {
	flex:1 1 calc(50% - var(--space-xxs));
}
.c-label-value-list--flex-equal .c-label-value-list__value {
	flex:1 1 50%;
}

/* Inline
----------------------------------------------------------------------------- */
.c-label-value-list--inline .c-label-value-list__item {
	display:inline-block;
	margin-right:var(--space-s);
}
.c-label-value-list--inline .c-label-value-list__item:last-child {
	margin-right:0;
}


/* Table style
----------------------------------------------------------------------------- */
.c-label-value-list--table {
	display:table;
}
.c-label-value-list--table .c-label-value-list__item {
	display:table-row;
}
.c-label-value-list--table .c-label-value-list__label {
	display:table-cell;
}
.c-label-value-list--table .c-label-value-list__value {
	display:table-cell;
	padding-left:var(--space-m);
}

/* Table style fixed label width
----------------------------------------------------------------------------- */
.c-label-value-list--table-fixed {
	width: 100%;
}
.c-label-value-list--table-fixed .c-label-value-list__label {
	width: 25%;
}

@media print {
	.c-label-value-list--table-fixed .c-label-value-list__item {
		padding:4px 0;
	}
}


/* Include colon after label
----------------------------------------------------------------------------- */
.c-label-value-list--colons .c-label-value-list__label::after {
	content:":";
}


/* Form summary style
----------------------------------------------------------------------------- */
.c-label-value-list--summary .c-label-value-list__item {
	padding: 20px 0;
	display:flex;
	flex-wrap:wrap;
	align-items:baseline;
}
@media print {
	.c-label-value-list--summary .c-label-value-list__item {
		padding:2px 0;
	}
}
.c-label-value-list--summary .c-label-value-list__item:not(:last-child) {
	border-bottom: 1px solid #ccc;
}
.c-label-value-list--summary  .c-label-value-list__label,
.c-label-value-list--summary .c-label-value-list__value {
	width:100%;
}
@media screen and (min-width:540px) {
	.c-label-value-list--summary  .c-label-value-list__label {
		flex:1 1 calc(25% - var(--space-xxs));
		padding-right:20px;
		min-width:120px;
	}
	.c-label-value-list--summary .c-label-value-list__value {
		flex:1 1 50%;
	}
}
.c-label-value-list--summary .c-label-value-list__item--full .c-label-value-list__label {
	flex:1 1 100%;
}
