/* =============================================================================
 * Hero Carousel
============================================================================= */
.c-hero-carousel {
	height:300px;
	/* overflow:hidden; */
}
.no-js .c-hero-carousel {
	height:auto;
}
.c-hero-carousel .c-carousel__item {
	position:relative;
	min-height:300px;
	width:100%;
	background-position:center;
	background-size:cover;
}
.c-hero-carousel .c-carousel__item::after {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	background-color:#0061a1;
	background-color:var(--color-blue);
	content:"";
	opacity:0.6;
}
.c-hero-carousel__media {
	position: absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	overflow-y: hidden;
}
.c-hero-carousel__image {
	object-fit: cover;
	width: 100%;
	height: 100%;
}
.c-hero-carousel__inner {
	display:-ms-flexbox;
	display:flex;
	-ms-flex-align:center;
	align-items:center;
	max-width:1200px;
	width:100%;
	height:100%;
}
.c-hero-carousel__title {
	margin-bottom:12px;
	margin-bottom:var(--space-s);
	color:#fff;
	color:var(--color-white);
	font:var(--type-sz6-bold);
	text-shadow:0 0 20px rgba(0, 0, 0, 0.5);
}
.c-hero-carousel__content {
	position:relative;
	z-index:1;
	width:100%;
	padding:var(--space-l) 0 var(--space-xl);
	color:#fff;
	color:var(--color-white);
	font:var(--type-sz3-medium);
	text-shadow:0 0 20px rgba(0, 0, 0, 0.5);
	opacity:0;
	transition:opacity 0.5s ease-in-out;
}

.no-js .c-hero-carousel__content {
	opacity:1;
}
.c-carousel__slide.is-active .c-hero-carousel__content {
	opacity:1;
}
.c-hero-carousel__nav {
	position:absolute;
	bottom:var(--space-s);
	left:0;
	z-index:2;
	width:100%;
	text-align:center;
}
.c-hero-carousel__nav-list {
	margin:0;
	padding:0;
	list-style:none;
}
.c-hero-carousel__nav-item {
	display:inline-block;
	margin:0 3px;
}
.c-hero-carousel__nav-button {
	position:relative;
	width:20px;
	height:20px;
	margin:0;
	padding:0;
	border:1px solid var(--color-white);
	border-radius:50%;
	background:none;
	text-indent:-9999px;
}
.c-hero-carousel__nav-button[aria-current="true"]::after {
	position:absolute;
	top:50%;
	left:50%;
	width:14px;
	height:14px;
	border-radius:50%;
	background-color:var(--color-white);
	content:"";
	transform:translate(-50%, -50%);
}

@media (min-width:768px) {
	.c-hero-carousel {
		height:380px;
	}
	.c-hero-carousel .c-carousel__wrapper {
		overflow-x:hidden;
		overflow-y:hidden;
	}
	.c-hero-carousel .c-carousel__item {
		min-height:380px;
	}
	.c-hero-carousel__content {
		width:calc(100% - 300px);
		padding:var(--space-l) 0;
		font:var(--type-sz4-medium);
	}
	.c-hero-carousel__title {
		font:var(--type-sz8-bold);
	}
	.c-hero-carousel__nav {
		top:0;
		left:50%;
		z-index:0;
		max-width:1200px;
		width:100%;
		height:100%;
		transform:translateX(-50%);
	}
	.is-sliding .c-hero-carousel__nav {
		z-index:2;
	}
	.c-hero-carousel__nav-list {
		position:absolute;
		top:50%;
		right:0;
		width:260px;
		transform:translateY(-50%);
	}
	.c-hero-carousel__nav-item {
		display:block;
	}
	.c-hero-carousel__nav-button {
		width:100%;
		height:auto;
		margin-bottom:var(--space-s);
		padding:var(--space-s) var(--space-s);
		border:0;
		border-radius:0;
		background-color:var(--color-white);
		color:var(--color-blue);
		font:var(--type-sz4);
		text-indent:0;
		cursor:pointer;
		transition:all 150ms;
	}
	.c-hero-carousel__nav-button:focus,
	.c-hero-carousel__nav-button:hover,
	.c-hero-carousel__nav-button[aria-current="true"] {
		background-color:var(--color-blue);
		color:var(--color-white);
	}
	.c-hero-carousel__nav-button::after {
		display:none;
	}
}

/* Theme variants */
.c-hero-carousel .c-carousel__item--blue::after,
.c-hero-carousel .c-carousel__item--blue-dark::after,
.c-hero-carousel .c-carousel__item--green-dark::after,
.c-hero-carousel .c-carousel__item--purple::after,
.c-hero-carousel .c-carousel__item--grey-dark::after {
	display:none;
}
.c-carousel__item--blue {
	background-color:var(--color-blue);
}
.c-carousel__item--blue-dark {
	background-color:var(--color-blue-dark);
}
.c-carousel__item--green-dark {
	background-color:var(--color-green-dark);
}
.c-carousel__item--purple {
	background-color:var(--color-purple);
}
.c-carousel__item--grey-dark {
	background-color:var(--color-grey-dark);
}

/* Black overlay */
.c-hero-carousel--black .c-carousel__item::after {
	background-color:var(--color-black);
}

/* Transparent overlay */
.c-hero-carousel--none .c-carousel__item::after {
	background-color:transparent;
}
.c-hero-carousel--none .c-hero-carousel__content {
	text-shadow: 4px 4px 10px #000;
}
.c-hero-carousel--none .c-hero-carousel__title {
	margin: 0;
	text-shadow: 4px 4px 10px #000;
}

.c-hero-carousel__inner-with-search {
	justify-content: center;
}

.c-hero-carousel__content-search {
	width: 100%;
	max-width: 400px;
}

.c-hero-carousel__content-search input {
	border: none !important;
	padding: 20px 70px 20px 20px !important;
	z-index: 200;
}
@media (min-width:768px) {
	.c-hero-carousel__content-search {
		max-width: 50% !important;
	}
}