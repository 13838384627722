/* =============================================================================
 * Alphabetic index
============================================================================= */
.c-alphabetic-index {
	padding-top:calc(var(--space-m) - var(--space-xs));
	border-top:2px solid var(--color-grey-light);
}
.c-alphabetic-index li {
	display:inline-block;
}
.c-alphabetic-index__anchors a,
.c-alphabetic-index__disabled-link {
	display:inline-block;
	margin:var(--space-xs) var(--space-s) var(--space-xs) 0;
}
.c-alphabetic-index__disabled-link {
	color:var(--color-grey-dark);
}
.c-alphabetic-index__section-title {
	margin:var(--space-m) 0 var(--space-s);
}
.c-alphabetic-index__table {
	margin:0;
	table-layout:fixed;
}
.c-alphabetic-index__table th {
	height:0;
	padding:0;
	border:none;
}
.c-alphabetic-index__table td {
	padding:var(--space-s) var(--space-s) var(--space-s) 0;
}
