/* =============================================================================
 * Supplemental navigation
 * postcss-bem-linter: define supplemental-navigation
============================================================================= */
.c-supplemental-navigation ul {
	display:flex;
	margin:0;
	margin-top:24px;
	margin-top:var(--space-m);
	padding:0;
	list-style:none;
}
.c-supplemental-navigation__item {
	margin-top:12px;
	margin-top:var(--space-s);
}
.c-supplemental-navigation__link,
.c-supplemental-navigation__link:visited {
	display:block;
	position:relative;
	padding-left:var(--space-m);
	color:#202020;
	color:var(--color-black);
	text-decoration:none;
}
.c-supplemental-navigation__user {
	position: relative;
	padding-left: var(--space-m);
}
.c-supplemental-navigation__user .c-supplemental-navigation__link-byline {
	display: inline-block;
	margin-right: var(--space-s);
}
.c-supplemental-navigation__logout-icon {
	height: 12px;
	width: 12px;
	margin-left:4px;
}
.c-supplemental-navigation__link-icon {
	position:absolute;
	top:0;
	left:0;
}
.c-supplemental-navigation__link-title {
	display:block;
	margin-bottom:6px;
	margin-bottom:var(--space-xs);
	font:18px/30px "Gill Sans Medium", Arial, sans-serif;
	font:var(--type-sz4-medium);
	line-height:1;
	text-decoration: none;
	color:inherit;
}
.c-supplemental-navigation__link-byline {
	position: relative;
	display:block;
	color:#666;
	color:var(--color-grey-dark);
	font:13px/18px "Gill Sans Medium", Arial, sans-serif;
	font:var(--type-sz2-medium);
}
a.c-supplemental-navigation__link-byline {
	text-decoration:none;
	color: var(--color-blue);
}
a.c-supplemental-navigation__link-byline:hover {
	text-decoration:underline;
}
.c-supplemental-navigation--narrow ul {
	display:flex;
	flex-wrap:wrap;
	margin-top:0;
	margin-bottom:calc(var(--space-s) * -1);
}
.c-supplemental-navigation--narrow .c-supplemental-navigation__item {
	width:50%;
	margin-top:0;
	margin-bottom:var(--space-s);
}
.c-supplemental-navigation--narrow .c-supplemental-navigation__link-title {
	font:var(--type-sz4-medium);
	line-height:1;
}

@supports(display:grid) {
	.c-supplemental-navigation--narrow ul {
		display:grid;
		margin:0;
		grid-template-columns:1fr 1fr;
		grid-gap:var(--space-s);
	}
	.c-supplemental-navigation--narrow .c-supplemental-navigation__item {
		width:auto;
		margin:0;
	}
}

@media screen and (min-width:768px) {
	.c-supplemental-navigation ul {
		margin-top:0;
	}
	.c-supplemental-navigation__item {
		margin-top:0;
		margin-right:var(--space-l);
	}
	.c-supplemental-navigation__item:last-child {
		margin-right:0;
		padding-left: 36px;
		padding-left: 39px;
		border-left: 1px solid #999;
	}
}

@media screen and (min-width:768px) and (max-width:1120px) {
	.c-supplemental-navigation__item {
		margin-right:var(--space-s);
	}
	.c-supplemental-navigation__item:last-child {
		padding-left:var(--space-s);
	}
	.c-supplemental-navigation__link-title {
		font:var(--type-sz3-medium);
		line-height:1;
	}
}

@media print {
	.c-supplemental-navigation {
		display:none;
	}
}
