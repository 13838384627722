/* =============================================================================
 * Section
 * postcss-bem-linter: define c-section
============================================================================= */
.c-section {
	padding-top:48px;
	padding-top:var(--space-xl);
	padding-bottom:48px;
	padding-bottom:var(--space-xl);
}
.c-section--grey {
	background-color:#f4f4f4;
	background-color:var(--color-grey-lightest);
}

.c-section--padding-top-none {
	padding-top: 0px;
}

.c-section--padding-bottom-none {
	padding-bottom: 0px;
}

/* Remove top padding if two sections with the same background color appear after each other */
.c-section:not(.c-section--grey) + .c-section:not(.c-section--grey),
.c-section--grey + .c-section--grey {
	padding-top:0;
}
