/* =============================================================================
 * Event Carousel
============================================================================= */
.c-event-carousel {
	overflow:hidden;
	padding-bottom:var(--space-l);
}
.c-event-carousel.has-navigation {
	padding-bottom:var(--space-l);
}
.c-event-carousel .c-carousel__wrapper {
	margin-right:calc(var(--space-m) * -1);
	margin-left:calc(var(--space-m) * -1);
}
.c-event-carousel .c-carousel__slide {
	padding:0 var(--space-m);
}
.c-event-carousel .c-carousel__item {
	height:auto;
}
.c-event-carousel .c-carousel__nav {
	margin-top:var(--space-s);
}
.c-event-carousel .c-carousel__nav-list {
	margin:0;
	padding:0;
	list-style:none;
	text-align:center;
}
.c-event-carousel .c-carousel__nav-item {
	display:inline-block;
	margin:0 var(--space-xxs);
}
.c-event-carousel .c-carousel__nav-button {
	position:relative;
	width:16px;
	height:16px;
	overflow:hidden;
	padding:0;
	border:1px solid var(--color-blue);
	border-radius:50%;
	background:none;
	text-indent:-9999px;
	cursor:pointer;
}
.c-event-carousel .c-carousel__nav-button::after {
	position:absolute;
	top:50%;
	left:50%;
	width:10px;
	height:10px;
	border-radius:50%;
	background-color:var(--color-blue);
	content:"";
	opacity:0;
	transition:opacity 150ms ease;
	transform:translate(-50%, -50%);
}
.c-event-carousel .c-carousel__nav-button[aria-current]::after,
.c-event-carousel .c-carousel__nav-button:hover::after {
	opacity:1;
}
.c-event-carousel .c-carousel__nav-button:active {
	outline:none;
}

@media (min-width:768px) {
	.c-event-carousel {
		overflow:visible;
		padding-bottom:var(--space-l);
	}
	.c-event-carousel .c-carousel__wrapper {
		overflow-x:hidden;
		overflow-y:hidden;
		margin-right:calc(var(--space-m) * -1);
		margin-left:calc(var(--space-m) * -1);
	}
	.c-event-carousel .c-carousel__nav {
		margin-top:0;
	}
	.c-event-carousel .c-carousel__slide {
		padding:0 var(--space-m);
	}
	.c-event-carousel .c-carousel__prev-button,
	.c-event-carousel .c-carousel__next-button {
		width:var(--space-l);
		border:0;
		background:var(--color-white);
	}
	.c-event-carousel .c-carousel__prev-button {
		left:calc(var(--space-l) * -1);
		cursor:pointer;
	}
	.c-event-carousel .c-carousel__next-button {
		right:calc(var(--space-l) * -1);
		cursor:pointer;
	}
	.c-event-carousel .c-icon {
		transition:color 150ms ease;
	}
	.c-event-carousel .c-carousel__prev-button:hover .c-icon,
	.c-event-carousel .c-carousel__next-button:hover .c-icon {
		color:var(--color-blue);
	}
}
