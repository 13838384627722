/* =============================================================================
 * Search form
 * postcss-bem-linter: define search-form
============================================================================= */
.c-search-form {
	display:-ms-flexbox;
	display:flex;
	flex:1 1 auto;
	max-width:260px;
}
.c-search-form--full-width {
	max-width:none;
}
.c-search-form__form {
	flex:1 1 auto;
	margin:0;
}
.c-search-form__controls {
	display:-ms-flexbox;
	display:flex;
	position:relative;
	height:100%;
}
.c-search-form__controls > * {
	flex:1 1 auto;
}
.c-search-form input[type="text"] {
	display:flex;
	flex:1 1 auto;
	padding:12px 50px 12px 24px;
	padding:var(--space-s) 50px var(--space-s) var(--space-m);
	border:0;
	border:3px solid #0061a1;
	border:3px solid var(--color-blue);
	border-radius:30px;
	z-index: 200;
}
.c-search-form input[type="text"]:focus {
	border-color:#004675;
	border-color:var(--color-blue-dark);
}
.c-search-form__submit {
	position:absolute;
	top:3px;
	right:-3px;
	bottom:3px;
	width:50px;
	padding:0;
	border:0;
	background:none;
	color:#0061a1;
	color:var(--color-blue);
	cursor:pointer;
	z-index: 300;
}

/* Search button positioning in hero-search
----------------------------------------------------------------------------- */
.c-hero-carousel__search .c-search-form .c-search-form__submit {
	right:8px;
}

/* Narrow (used in mobile navigation)
----------------------------------------------------------------------------- */
.c-search-form--narrow {
	max-width:none;
	padding:var(--space-xs) calc(var(--space-xs) * 2);
	background:var(--color-blue);
}

.c-search-form--narrow-home {
	position: absolute;
	top: 200px;
	left: 50%;
	width: 75%;
  	transform: translateX(-50%);
	background: transparent;
}

.c-search-form--narrow-home input {
	border: none !important;
	padding: 12px !important;
}

@media screen and (max-width:1120px) {
	.c-search-form input[type="text"] {
		padding:var(--space-xs) 50px var(--space-xs) var(--space-s);
		font:var(--type-sz3-medium);
	}
}

/* autocomplete for header-search
----------------------------------------------------------------------------- */
.c-header .c-search-form .autocomplete__menu--overlay {
	top: 24px;
	padding-top: 28px;
}

@media screen and (max-width:768px) {
	.c-search-form--narrow .autocomplete__menu--overlay {
		top: 18px;
	}
}

#headersearch:focus-visible {
    outline: 2px solid #FFFFFF;
}