/* =============================================================================
 * Float
============================================================================= */
.o-float-left {
	float:left;
}
.o-float-right {
	float:right;
}
.o-float-left-editor {
	float:left;
	margin-top:5px;
	margin-right:var(--space-m);
}
.o-float-right-editor {
	float:right;
	margin-top:5px;
	margin-left:var(--space-m);
}
