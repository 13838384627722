/* =============================================================================
 * Space
============================================================================= */
.t-space-top {
	margin-top:var(--space-xs) !important;
}
.t-space-top-s {
	margin-top:var(--space-s) !important;
}
.t-space-top-m {
	margin-top:var(--space-m) !important;
}
.t-space-top-l {
	margin-top:var(--space-l) !important;
}
.t-space-top-xl {
	margin-top:var(--space-xl) !important;
}
.t-space-top-xxl {
	margin-top:var(--space-xxl) !important;
}
.t-space-top-xxxl {
	margin-top:var(--space-xxxl) !important;
}
.t-space-top-none {
	margin-top:0 !important;
}
.t-space-bottom {
	margin-bottom:var(--space-xs) !important;
}
.t-space-bottom-s {
	margin-bottom:var(--space-s) !important;
}
.t-space-bottom-m {
	margin-bottom:var(--space-m) !important;
}
.t-space-bottom-l {
	margin-bottom:var(--space-l) !important;
}
.t-space-bottom-xl {
	margin-bottom:var(--space-xl) !important;
}
.t-space-bottom-xxl {
	margin-bottom:var(--space-xxl) !important;
}
.t-space-bottom-xxxl {
	margin-bottom:var(--space-xxxl) !important;
}
.t-space-bottom-none {
	margin-bottom:0 !important;
}
.t-space-left {
	margin-left:var(--space-xs) !important;
}
.t-space-left-s {
	margin-left:var(--space-s) !important;
}
.t-space-left-m {
	margin-left:var(--space-m) !important;
}
.t-space-left-l {
	margin-left:var(--space-l) !important;
}
.t-space-left-xl {
	margin-left:var(--space-xl) !important;
}
.t-space-left-xxl {
	margin-left:var(--space-xxl) !important;
}
.t-space-left-xxxl {
	margin-left:var(--space-xxxl) !important;
}
.t-space-left-none {
	margin-left:0 !important;
}
.t-space-right {
	margin-right:var(--space-xs) !important;
}
.t-space-right-s {
	margin-right:var(--space-s) !important;
}
.t-space-right-m {
	margin-right:var(--space-m) !important;
}
.t-space-right-l {
	margin-right:var(--space-l) !important;
}
.t-space-right-xl {
	margin-right:var(--space-xl) !important;
}
.t-space-right-xxl {
	margin-right:var(--space-xxl) !important;
}
.t-space-right-xxxl {
	margin-right:var(--space-xxxl) !important;
}
.t-space-right-none {
	margin-right:0 !important;
}
