/* =============================================================================
 * Scrollable elements
 *
 * [1]: Enable smoother scrolling in iOS
 *
 * postcss-bem-linter: define scrollable-element
============================================================================= */
.c-scrollable-element {
	position:relative;
	width:100%;
	overflow:hidden;
	margin-bottom:30px;
	-webkit-overflow-scrolling:touch; /* [1] */
}
.c-scrollable-element__inner {
	width:100%;
	overflow:scroll;
	overflow-x:scroll;
	overflow-y:visible;
}


/* Add shadows that indicate that there's content to the left or right of
 * what is currently visible.
----------------------------------------------------------------------------- */
.c-scrollable-element::before {
	position:absolute;
	top:10px;
	right:100%;
	bottom:10px;
	width:50px;
	border-radius:0 15px 15px 0 / 0 125px 125px 0;
	box-shadow:6px 0 5px rgba(0, 0, 0, 0.32);
	content:"";
	pointer-events:none;
}
.c-scrollable-element::after {
	position:absolute;
	top:10px;
	bottom:10px;
	left:100%;
	width:50px;
	border-radius:15px 0 0 15px / 125px 0 0 125px;
	box-shadow:-6px 0 5px rgba(0, 0, 0, 0.32);
	content:"";
	pointer-events:none;
}
.c-scrollable-element--scrolled-left::before,
.c-scrollable-element--scrolled-right::after {
	opacity:0;
}
