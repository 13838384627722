/* =============================================================================
 * Padded sides
 * postcss-bem-linter: define padded-sides
============================================================================= */
.o-padded-sides {
	padding-right:var(--space-s);
	padding-left:var(--space-s);
}

@media screen and (min-width:600px) {
	.o-padded-sides {
		padding-right:var(--space-l);
		padding-left:var(--space-l);
	}
}
