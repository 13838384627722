/* =============================================================================
 * Form item
 *
 * 1. #767676 is the lightest gray that meets WCAG AA, assuming the
 *    background is #fff.
 *
 * postcss-bem-linter: define form-item
============================================================================= */
.c-form-item:not(:last-of-type){
	margin-bottom:15px;
}

/* Inline
----------------------------------------------------------------------------- */
/* .c-form-item--inline {
	margin-top:-6px;
	margin-left:16px;
	padding-left:20px;
	border-left:4px solid var(--color-blue);
} */

/* Label
----------------------------------------------------------------------------- */
.c-form-item__label {
	margin-bottom:8px;
}
.c-form-item__label--before {
	margin-right:calc(30px / 4);
	margin-right:calc(var(--hspace) / 4);
	margin-bottom:0;
}
.c-form-item__label--after {
	margin-bottom:0;
	margin-left:calc(30px / 4);
	margin-left:calc(var(--hspace) / 4);
}
.c-form-item__label-extra,
.c-form-item__label-format,
.c-form-item__label-optional {
	color:#767676; /* 1 */
	font:var(--type-sz3);
}
.c-form-item--disabled .c-form-item__label {
	color:#767676; /* 1 */
}


/* Field
 *
 * 1. [class] increases specificity to override attribute selectors in
 *    b-reset.css and e-elements.css.
----------------------------------------------------------------------------- */
.c-form-item__control--text[class] {
	/* 1 */
	padding:10px 15px;
	border:2px solid #ccc;
	line-height:inherit;
}
.c-form-item__control--text[disabled],
.c-form-item__control--text[readonly] {
	color:#767676;
}
.c-form-item__control--text:not([disabled]):hover,
.c-form-item__control--text:not([disabled]):focus {
	border-color:#666;
}

/*
 * Invalid
 */
.c-form-item--invalid {
	position:relative;
}
.c-form-item--invalid::before {
	position:absolute;
	top:0;
	left:-16px;
	width:6px;
	height:100%;
	background-color:var(--color-red);
	content:"";
}
.c-form-item--invalid .c-form-item__control,
.c-form-item--invalid .c-form-item__control:hover,
.c-form-item--invalid .c-form-item__control:focus {
	border-width:2px;
	border-color:var(--color-red);
}

/*
 * Valid
 */
.c-form-item--valid .c-form-item__control--text,
.c-form-item--valid .c-form-item__control--text:hover,
.c-form-item--valid .c-form-item__control--text:focus {
	border-color:#0f0;
}


/* Text
----------------------------------------------------------------------------- */
.c-form-item__text {
	display:block;
	width:85%;
	margin-bottom:0;
}

/* Signature
----------------------------------------------------------------------------- */
.c-form-item__control--signature[type="text"] {
	border: none;
	border-bottom: 2px solid;
	margin-bottom: 6px;
}

/* File
----------------------------------------------------------------------------- */
.c-form-item__control--file {
	width:100%;
	padding:10px 15px;
	border:2px solid #ccc;
}
.c-form-item__control--file:hover {
	border-color:#666;
}

/* Date
----------------------------------------------------------------------------- */
.c-form-item__control--date[type="date"],
.c-form-item__control--tel[type="tel"],
.c-form-item__control--email[type="email"],
.c-form-item__control--time[type="time"],
.c-form-item__control--text-area {
	width:100%;
	padding:10px 15px;
	border:2px solid #ccc;
}
.c-form-item__control--date[type="date"]:hover,
.c-form-item__control--tel[type="tel"]:hover,
.c-form-item__control--email[type="email"]:hover,
.c-form-item__control--time[type="time"]:hover,
.c-form-item__control--text-area:hover {
	border-color:#666;
}

/*
 * Error text
 */
.c-form-item__text--error-text {
	width:100%;
	margin:-6px 0 6px 0;
	color:var(--color-red);
	font:var(--type-sz4-medium);
}

/* Hint
----------------------------------------------------------------------------- */
.c-form-item__hint {
	width:100%;
	margin:-8px 0 6px 0;
	color:#767676;
	font:var(--type-sz3);
	line-height:1.4;
}
.c-form-item__hint p:last-of-type {
	margin-bottom:0;
}
.c-form-item__hint--after {
	margin-top:0;
	margin-left:0.5em;
}

/* Hint
----------------------------------------------------------------------------- */
.c-form-item__action {
	display:none;
}
.js .c-form-item__action {
	display: block;
}

/* Unit
----------------------------------------------------------------------------- */
.c-form-item--unit {
	position:relative;
}
.c-form-item--unit .c-form-item__unit {
	position:absolute;
	right:12px;
	bottom:12px;
}
.c-form-item--unit .c-form-item__control {
	padding-right:110px;
}

@media screen and (min-width:768px) {
	.c-form-item--unit .c-form-item__control {
		padding-right:140px;
	}
}

/* Dividers
----------------------------------------------------------------------------- */
.c-form-item[data-formula] {
	margin-top: 25px;
	padding-top: 15px;
	border-top: 2px solid #666;
}

.c-form-item[data-control="data"] {
	margin-bottom: 0;
}
.c-form-item[data-control="data"] + .c-form-item[data-control="data"] {
	border-top: 1px solid #ccc;
}
.c-form-item[data-control="data"] + .c-form-item:not([data-control="data"]) {
	margin-top: 24px;
}

/* Dividers
----------------------------------------------------------------------------- */
