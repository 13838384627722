/* =============================================================================
 * Page header
 * postcss-bem-linter: define c-page-header
============================================================================= */
.c-page-header {
	display:flex;
	flex-wrap:wrap;
	margin-bottom:var(--space-m);
}
.c-page-header__content {
	flex:1 1 70%;
	max-width:800px;
}
.c-page-header__additional-content {
	align-self:center;
	flex:1 1 30%;
}
.c-page-header__title {
	display:flex;
	align-items:baseline;
	margin-bottom:var(--space-s);
	font:var(--type-sz5-medium);
}
.c-page-header__intro {
	font:var(--type-sz4-medium);
}

@media screen and (min-width:768px) {
	.c-page-header__title {
		font:var(--type-sz7-medium);
	}
	.c-page-header__intro {
		font:var(--type-sz5-medium);
	}
}

@media screen and (max-width:767px) {
	.c-page-header__additional-content {
		flex:1 1 100%;
	}
	.c-page-header__icon {
		width:32px;
		height:32px;
	}
}
