.c-category-filter__form {
	width: 100%;
	display:flex;
	flex-wrap: wrap;
}
.c-category-filter__section {
	margin-right: var(--space-m);
	margin-bottom: var(--space-m);
}
.c-category-filter__count {
	font-family: "Gill Sans Medium", Arial, sans-serif;
	margin-top: var(--space-s);
	margin-bottom: 0;
}
@media screen and (min-width:992px) {
	.c-category-filter__container {
		flex-wrap: nowrap;
	}
	.c-category-filter__section {
		margin-bottom: none;
		width: calc(33% - var(--space-m));
	}
}

.delta-sort {
	display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: var(--space-m);
    align-items: flex-end;
    width: 100%;
}
.delta-sort p {
	margin: 0;
	line-height: 1;
}
.delta-sort .c-custom-select {
	max-width: 300px;
}
