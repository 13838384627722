/* =============================================================================
 * Form
 *
 * 1. Remove any margin-bottom from .c-form__fieldset as the o-grid system is
 *    expected and will add vertical margins.
 *
 * postcss-bem-linter: define form
============================================================================= */
.c-form {
	margin-bottom:0; /* 1 */
}
.c-form--m {
	max-width:800px;
}

/* Inline form
----------------------------------------------------------------------------- */
.c-form--inline {
	display:inline-block;
}


/* Fieldset
 *
 * 1. Remove any margin-bottom from .c-form__fieldset as the o-grid system is
 *    expected and will add vertical margins.
 * 2. Add some vertical space between fieldset children.
----------------------------------------------------------------------------- */
.c-form__fieldset {
	margin-bottom:0; /* 1 */
	border:2px solid #ccc;
}
.c-form__fieldset h3 {
	margin:20px 0 12px 0;
}
.c-form__fieldset--section {
	margin-bottom:var(--space-l);
	padding:var(--space-s) var(--space-s) var(--space-m) var(--space-s);
}
@media print {
	.c-form__fieldset--section {
		margin-bottom:var(--space-xs);
		font-size: 130%;
	}
}
@media screen and (min-width:768px) {
	.c-form__fieldset--section {
		padding:var(--space-s) var(--space-m) var(--space-m) var(--space-m);
	}
}
.c-form__fieldset--section > legend {
	margin-bottom:var(--space-s);
	padding:0 var(--space-s);
	font:var(--type-sz6-medium);
}
.c-form__fieldset--inline {
	position:relative;
	margin-bottom:15px;
	padding:0;
	border:none;
}
.c-form__fieldset--inline > legend {
	margin:0 0 8px 0;
	font:var(--type-sz4-medium);
}

@media print {
	.c-form__fieldset {
		border:none;
		border-top:2px solid #ccc;
	}

	.c-form__fieldset legend {
		page-break-inside: avoid;
	}
}

/*
 * Invalid
 */
.c-form__fieldset--invalid::before {
	position:absolute;
	top:0;
	left:-18px;
	width:6px;
	height:100%;
	background-color:var(--color-red);
	content:"";
}
/* .c-form__fieldset--invalid:focus-within::before {
	background-color:transparent;
}
.c-form__fieldset--invalid:focus-within .c-form-item__text--error-text {
	display:none;
} */

/* Footer
 *
 * 1. Provide space between areas when stacked, while still preserving the same
 *    margin between the footer and whatever is above it.
----------------------------------------------------------------------------- */
.c-form__footer {
	display:-ms-flexbox;
	display:flex;
	-ms-flex-pack:justify;
	justify-content:space-between;
	-ms-flex-wrap:wrap;
	flex-wrap:wrap;
	margin-top:-15px; /* 1 */
}
.c-form__footer-area {
	display:-ms-flexbox;
	display:flex;
	-ms-flex-wrap:wrap;
	flex-wrap:wrap;
	-ms-flex-align:center;
	align-items:center;
}
.c-form__footer-area:not(:last-child) {
	margin-right:30px;
}
.c-form__footer-area-item {
	margin-top:15px; /* 1 */
}
.c-form__footer-area-item:not(:last-child) {
	margin-right:30px;
}
