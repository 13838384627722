/* =============================================================================
 * Breadcrumbs
 * postcss-bem-linter: define breadcrumbs
============================================================================= */
.c-breadcrumbs {
	/* display:none; */
	font:var(--type-sz3-medium);
}

@media screen and (min-width:768px) {
	.c-breadcrumbs {
		display:block;
	}
	.c-breadcrumbs__link,
	.c-breadcrumbs__link:visited {
		text-decoration:none;
	}
}

@media print {
	.c-breadcrumbs {
		display:block;
	}
}
