/* =============================================================================
 * Accordion
 * [1] Make the button expand the entire title surface
============================================================================= */
.c-accordion {
	margin:0;
}
.c-accordion__item {
	margin-bottom:12px;
	margin-bottom:var(--space-s);
}
.c-accordion__title {
	border:1px solid #202020;
	border:1px solid var(--color-black);
	color:#0061a1;
	color:var(--color-blue);
	font:18px/30px "Gill Sans Medium", Arial, sans-serif;
	font:var(--type-sz4-medium);
	transition:background 200ms;
}
.no-js .c-accordion__title {
	padding:24px;
	padding:var(--space-m);
}
.no-js .c-accordion__title,
.c-accordion__item.is-expanded .c-accordion__title {
	border-color:#0061a1;
	border-color:var(--color-blue);
	background-color:#0061a1;
	background-color:var(--color-blue);
	color:#fff;
	color:var(--color-white);
}
.c-accordion button {
	display:-ms-flexbox;
	display:flex;
	-ms-flex-pack:justify;
	justify-content:space-between;
	-ms-flex-align:center;
	align-items:center;
	width:100%;
	padding:24px;
	padding:var(--space-s);
	border:none;
	background:transparent;
	color:inherit;
	font:inherit;
	text-align:left;
	cursor:pointer;
}
.c-accordion button span {
	display:inline-block;
	flex:1 1 auto;
}
.c-accordion .c-icon {
	flex:0 0 auto;
	width:12px;
	margin-left:var(--space-s);
	transition:transform 100ms;
	fill:var(--color-blue);
}
.c-accordion__item.is-expanded .c-accordion__title .c-icon {
	transform:rotate(-45deg);
	fill:var(--color-white);
}
.js .c-accordion__content {
	height:0;
}
.c-accordion__content {
	height:auto;
	overflow:hidden;
	margin:0;
	padding:0;
	background-color:#f4f4f4;
	background-color:var(--color-grey-lightest);
	font:18px/30px "Gill Sans Medium", Arial, sans-serif;
	font:var(--type-sz3-medium);
	transition:height 400ms;
}
.c-accordion__content-inner {
	padding:var(--space-s);
}
.c-accordion__content :last-child {
	margin-bottom:0;
}
.c-accordion__item.is-collapsed .c-accordion__content,
.c-accordion__item.is-expanded .c-accordion__content {
	height:auto;
}

@media screen and (min-width:768px) {
	.c-accordion button {
		padding:var(--space-m);
	}
	.c-accordion .c-icon {
		margin-left:var(--space-m);
	}
	.c-accordion__content-inner {
		padding:var(--space-m);
	}
}
