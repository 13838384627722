/* =============================================================================
 * Radio button
 *
 * 1. We use flex to ensure that if the label text wraps, it does not wrap below
 *    the radio button.
 *
 * postcss-bem-linter: define radio
============================================================================= */
.c-radio {
	display:-ms-flexbox;
	display:flex; /* 1 */
	-ms-flex-align:center;
	align-items:center;
}
.c-radio input {
	-ms-flex:none;
	flex:none; /* 1 */
}
.c-radio:not(.c-form-item) label {
	margin-left:0.5em;
}
