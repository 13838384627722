/* =============================================================================
 * Grid
 * Uses CSS grid in browsers that support it and falls back to flex in others.
 * Browsers that support neither get no grid.
 *
 * 1. Negative horizontal margins on the parent combine with padding on the
 *    children to create gutters in the flex based grid. This is overwritten
 *    in browsers that support CSS grid.
 *
 * NOTE: This is not defined as a BEM component since postcss-bem-linter can’t
 *       handle `~` and `:` inside attribute selectors properly and incorrectly
 *       reports the selectors we use to match `data-size` as errors.
============================================================================= */
.o-grid {
	display:-ms-flexbox;
	display:flex;
	-ms-flex-wrap:wrap;
	flex-wrap:wrap;
	align-items:flex-start;
	margin:0 calc(var(--grid-gutter) * -0.5); /* 1 */
	margin-bottom:calc(var(--grid-gutter) * -0.5);
	padding:0;
}
.o-grid__column {
	display:-ms-flexbox;
	display:flex;
	-ms-flex-direction:column;
	flex-direction:column;
	margin-bottom:calc(var(--grid-gutter) * 0.5);
	padding:0 calc(var(--grid-gutter) * 0.5); /* 1 */
}


/* Breakpoint: sm (and constant width)
----------------------------------------------------------------------------- */
.o-grid__column[data-size="1"],
.o-grid__column[data-size~="sm:1"] {
	width:8.3333%; /* 1/12 */
	grid-column:span 1;
}
.o-grid__column[data-size="2"],
.o-grid__column[data-size~="sm:2"] {
	width:16.6667%; /* 2/12 */
	grid-column:span 2;
}
.o-grid__column[data-size="3"],
.o-grid__column[data-size~="sm:3"] {
	width:25%; /* 3/12 */
	grid-column:span 3;
}
.o-grid__column[data-size="4"],
.o-grid__column[data-size~="sm:4"] {
	width:33.3333%; /* 4/12 */
	grid-column:span 4;
}
.o-grid__column[data-size="5"],
.o-grid__column[data-size~="sm:5"] {
	width:41.6667%; /* 5/12 */
	grid-column:span 5;
}
.o-grid__column[data-size="6"],
.o-grid__column[data-size~="sm:6"] {
	width:50%; /* 6/12 */
	grid-column:span 6;
}
.o-grid__column[data-size="7"],
.o-grid__column[data-size~="sm:7"] {
	width:58.3333%; /* 7/12 */
	grid-column:span 7;
}
.o-grid__column[data-size="8"],
.o-grid__column[data-size~="sm:8"] {
	width:66.6667%; /* 8/12 */
	grid-column:span 8;
}
.o-grid__column[data-size="9"],
.o-grid__column[data-size~="sm:9"] {
	width:75%; /* 9/12 */
	grid-column:span 9;
}
.o-grid__column[data-size="10"],
.o-grid__column[data-size~="sm:10"] {
	width:83.3333%; /* 10/12 */
	grid-column:span 10;
}
.o-grid__column[data-size="11"],
.o-grid__column[data-size~="sm:11"] {
	width:91.6667%; /* 11/12 */
	grid-column:span 11;
}
.o-grid__column[data-size="12"],
.o-grid__column[data-size~="sm:12"] {
	width:100%; /* 12/12 */
	grid-column:span 12;
}


/* Breakpoint: md
----------------------------------------------------------------------------- */
@media screen and (min-width:769px) {
	.o-grid__column[data-size~="md:1"] {
		width:8.3333%; /* 1/12 */
		grid-column:span 1;
	}
	.o-grid__column[data-size~="md:2"] {
		width:16.6667%; /* 2/12 */
		grid-column:span 2;
	}
	.o-grid__column[data-size~="md:3"] {
		width:25%; /* 3/12 */
		grid-column:span 3;
	}
	.o-grid__column[data-size~="md:4"] {
		width:33.3333%; /* 4/12 */
		grid-column:span 4;
	}
	.o-grid__column[data-size~="md:5"] {
		width:41.6667%; /* 5/12 */
		grid-column:span 5;
	}
	.o-grid__column[data-size~="md:6"] {
		width:50%; /* 6/12 */
		grid-column:span 6;
	}
	.o-grid__column[data-size~="md:7"] {
		width:58.3333%; /* 7/12 */
		grid-column:span 7;
	}
	.o-grid__column[data-size~="md:8"] {
		width:66.6667%; /* 8/12 */
		grid-column:span 8;
	}
	.o-grid__column[data-size~="md:9"] {
		width:75%; /* 9/12 */
		grid-column:span 9;
	}
	.o-grid__column[data-size~="md:10"] {
		width:83.3333%; /* 10/12 */
		grid-column:span 10;
	}
	.o-grid__column[data-size~="md:11"] {
		width:91.6667%; /* 11/12 */
		grid-column:span 11;
	}
	.o-grid__column[data-size~="md:12"] {
		width:100%; /* 12/12 */
		grid-column:span 12;
	}
}


/* Breakpoint: lg
----------------------------------------------------------------------------- */
@media screen and (min-width:992px) {
	.o-grid__column[data-size~="lg:1"] {
		width:8.3333%; /* 1/12 */
		grid-column:span 1;
	}
	.o-grid__column[data-size~="lg:2"] {
		width:16.6667%; /* 2/12 */
		grid-column:span 2;
	}
	.o-grid__column[data-size~="lg:3"] {
		width:25%; /* 3/12 */
		grid-column:span 3;
	}
	.o-grid__column[data-size~="lg:4"] {
		width:33.3333%; /* 4/12 */
		grid-column:span 4;
	}
	.o-grid__column[data-size~="lg:5"] {
		width:41.6667%; /* 5/12 */
		grid-column:span 5;
	}
	.o-grid__column[data-size~="lg:6"] {
		width:50%; /* 6/12 */
		grid-column:span 6;
	}
	.o-grid__column[data-size~="lg:7"] {
		width:58.3333%; /* 7/12 */
		grid-column:span 7;
	}
	.o-grid__column[data-size~="lg:8"] {
		width:66.6667%; /* 8/12 */
		grid-column:span 8;
	}
	.o-grid__column[data-size~="lg:9"] {
		width:75%; /* 9/12 */
		grid-column:span 9;
	}
	.o-grid__column[data-size~="lg:10"] {
		width:83.3333%; /* 10/12 */
		grid-column:span 10;
	}
	.o-grid__column[data-size~="lg:11"] {
		width:91.6667%; /* 11/12 */
		grid-column:span 11;
	}
	.o-grid__column[data-size~="lg:12"] {
		width:100%; /* 12/12 */
		grid-column:span 12;
	}
}


/* The grid grid
 * Contains overrides used in browsers that supprt CSS grid
----------------------------------------------------------------------------- */
@supports (display:grid) {
	.o-grid {
		display:grid;
		margin:0;
		grid-template-columns:repeat(12, 1fr);
		grid-gap:calc(var(--grid-gutter) / 2);
	}
	.o-grid__column[data-size] {
		width:auto;
		margin-bottom:0;
		padding:0;
	}


	.o-grid--row-gap-none {
		row-gap: 0px;	
	}
	
	.o-grid--row-gap-s {
		row-gap: var(--space-xxs);	
	}

	.o-grid--row-gap-m {
		row-gap: var(--space-xs);	
	}

	.o-grid--row-gap-l {
		row-gap: var(--space-s);
	}

	.o-grid--row-gap-xl {
		row-gap: var(--space-m);	
	}

	.o-grid--row-gap-xxl {
		row-gap: var(--space-l);	
	}

	.o-grid--row-gap-xxxl {
		row-gap: var(--space-xl);	
	}

	@media screen and (min-width:769px) {
		.o-grid {
			grid-gap:36px;
			grid-gap:var(--grid-gutter);
		}
		.o-grid--gap-m {
			grid-gap:var(--space-m);
		}
		.o-grid--gap-s {
			grid-gap:var(--space-s);
		}

		.o-grid--row-gap-none {
			row-gap: 0px;	
		}
		.o-grid--row-gap-s {
			row-gap: var(--space-xs);	
		}
	
		.o-grid--row-gap-m {
			row-gap: var(--space-s);	
		}
	
		.o-grid--row-gap-l {
			row-gap: var(--space-m);	
		}
	
		.o-grid--row-gap-xl {
			row-gap: var(--space-l);	
		}
	
		.o-grid--row-gap-xxl {
			row-gap: var(--space-xl);	
		}
	
		.o-grid--row-gap-xxxl {
			row-gap: var(--space-xxl);	
		}
	}

	@media screen and (min-width:992px) {
		.o-grid--gap-xxl {
			grid-gap:48px;
			grid-gap:var(--space-xl);
		}
		
		.o-grid--row-gap-none {
			row-gap: 0px;	
		}

		.o-grid--row-gap-s {
			row-gap: var(--space-xs);	
		}
	
		.o-grid--row-gap-m {
			row-gap: var(--space-s);	
		}
	
		.o-grid--row-gap-l {
			row-gap: var(--space-m);	
		}
	
		.o-grid--row-gap-xl {
			row-gap: var(--space-l);	
		}
	
		.o-grid--row-gap-xxl {
			row-gap: var(--space-xl);	
		}
	
		.o-grid--row-gap-xxxl {
			row-gap: var(--space-xxl);	
		}

	}

	@media screen and (min-width:1200px) {
		.o-grid--gap-xxl {
			grid-gap:60px;
			grid-gap:var(--space-xxl);
		}
		
		.o-grid--row-gap-s {
			row-gap: var(--space-s);	
		}
	
		.o-grid--row-gap-m {
			row-gap: var(--space-m);	
		}
	
		.o-grid--row-gap-l {
			row-gap: var(--space-l);	
		}
	
		.o-grid--row-gap-xl {
			row-gap: var(--space-xl);	
		}
	
		.o-grid--row-gap-xxl {
			row-gap: var(--space-xxl);	
		}
	
		.o-grid--row-gap-xxxl {
			row-gap: var(--space-xxxl);	
		}
	}
}

/* Stretched
 * Make columns equal height
----------------------------------------------------------------------------- */
.o-grid--stretched .o-grid__column {
	align-self:stretch;
}
.o-grid--stretched .o-grid__column > * {
	flex:1 1 auto;
}


/* Collapsed
 * No margin or padding
----------------------------------------------------------------------------- */
.o-grid--collapsed {
	margin:0;
	grid-gap:0;
}
.o-grid--collapsed > .o-grid__column {
	margin-bottom:0;
	padding:0;
}


/* No vertical margin
----------------------------------------------------------------------------- */
.o-grid--collapsed-v {
	margin-bottom:0;
	grid-row-gap:0;
}
.o-grid--collapsed-v > .o-grid__column {
	margin-bottom:0;
}


/* Vertical alignment
 * The default is `stretch`
 * The `flex-start` and `flex-end` values are treated as `start` and `end` when
 * `display:grid` is used.
----------------------------------------------------------------------------- */
.o-grid--align-top {
	-ms-flex-align:start;
	align-items:flex-start;
}
.o-grid__column--align-top {
	-ms-flex-item-align:start;
	align-self:flex-start;
}
.o-grid--align-middle {
	-ms-flex-align:center;
	align-items:center;
}
.o-grid__column--align-middle {
	-ms-flex-item-align:center;
	align-self:center;
}
.o-grid--align-bottom {
	-ms-flex-align:end;
	align-items:flex-end;
}
.o-grid__column--align-bottom {
	-ms-flex-item-align:end;
	align-self:flex-end;
}


/* Horizontal alignment
----------------------------------------------------------------------------- */
.o-grid__column--align-left {
	margin-right:auto;
}
.o-grid__column--align-right {
	margin-left:auto;
}


/* Auto size
----------------------------------------------------------------------------- */
.o-grid__column[data-size="auto"] {
	-ms-flex:none;
	flex:none;
}


/* Print
 * Disable the grid when printing
----------------------------------------------------------------------------- */
@media print {
	.o-grid {
		display:block;
		margin:0;
	}
	.o-grid__column[data-size] {
		display:block;
		margin-bottom:30px;
		margin-bottom:var(--vspace);
		padding:0;
	}
}
